import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { AppSettings } from "../../constant";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
// import history from '../assets/images/file.png'
import history from "../assets/images/application.png";
import axios from "axios";
import axiosInstance from "../../axios-interceptor";

function ShowModal(details) {
  const { asset, data, onHide, show, historydata } = details;

  // console.log(details);

  return (
    <Modal
      centered
      className="modal fade show"
      {...details}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          History of Asset: {details.details.data.systemId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex justify-center items-center">
        <div className="w-full overflow-auto ">
          <table className="table-auto min-w-full whitespace-nowrap">
            <thead>
              <tr className=" whitespace-nowrap uppercase ">
                <th className="px-3 py-2">Assigned To Emp Id</th>
                <th className="px-3 py-2">Assigned To User</th>
                <th className="px-3 py-2">Assigned From</th>
                <th className="px-3 py-2">Assigned Till</th>
                <th className="px-3 py-2">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {historydata?.length > 0 ? (
                historydata?.map((value, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-200" : "bg-white"
                    }`}
                  >
                    <td className="px-3 py-2 font-normal uppercase ">
                      {value?.empId}
                    </td>
                    <td className="px-3 py-2 font-normal ">
                      {value?.assignedToUser}
                    </td>
                    <td className="px-3 py-2 font-normal ">
                      {value?.assignedFromDate
                        ? value?.assignedFromDate?.split("T")[0]
                        : "---"}
                    </td>
                    <td className="px-3 py-2 font-normal ">
                      {value?.assignedToDate?.split("T")[0] !== "0001-01-01"
                        ? `${value?.assignedToDate?.split("T")[0]}`
                        : "---"}
                    </td>
                    <th className="px-3 py-2 font-normal ">
                      {value?.remarks ? value.remarks : "---"}
                    </th>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-3 py-2 font-normal uppercase ">---</td>
                  <td className="px-3 py-2 font-normal ">---</td>
                  <td className="px-3 py-2 font-normal ">---</td>
                  <td className="px-3 py-2 font-normal ">---</td>
                  <th className="px-3 py-2 font-normal ">---</th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function History(data) {
  useEffect(() => {}, []);

  const [modalShow, setModalShow] = useState(false);

  const [historydata, setHistoryData] = useState([]);

  const assetId = data.data.systemId;

  let getHistoryDetails = async () => {
    try {
      let res = await axiosInstance.get(
        AppSettings.URLs.getAssetHistoryById + "?assetId=" + assetId
      );

      if (res !== null) {
        setHistoryData(res?.data?.response.history);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Tooltip title="History" placement="top" arrow>
        <div
          className="kr-tbl-view cursor-pointer action-item"
          onClick={() => {
            setModalShow(true);
            getHistoryDetails();
          }}
        >
          <span className="svg-icon">
            <img
              className=" h-[30px] w-[30px]"
              src={history}
              alt="history icon"
            />
          </span>
        </div>
      </Tooltip>

      <ShowModal
        historydata={historydata}
        details={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default History;
