import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { AppSettings } from "../../constant";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import axiosInstance from "../../axios-interceptor";

function AllcoateAsset(data) {
  const [isAllocateformOpen, setIsAllocateFormOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const customId = "custom-id-yes";
  const dateToFormat = new Date();
  const [inputValues, setInputValues] = useState({});
  const [userId, setUserId] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [allocateSelectedValue, setAllocateSelectedValue] = useState({
    assetid: { value: data.data?.id, label: data.data?.id },
    userid: {
      value: data.data.assignedToUserId,
      label: data.data.assignedToUserId,
    },
    username: {
      value: data.data.assignedToUserId,
      label: data.data.assignedTo ? data.data.assignedTo : "Select User",
    },
    assetType: { value: data.asset, label: data.asset },
    assetName: { value: data.data?.systemId, label: data.data?.systemId },
  });
  const [dataError, setDataError] = useState({
    username: false,
    assignedOn: false
  });

  // To get all user data
  const fetchAllUsers = async () => {
    try {
      const res = await axiosInstance.get(AppSettings.URLs.getAllUsers);
      const newDropdown = await res.data.response.users;

      newDropdown.unshift({
        id: 0,
        username: "No User",
      });
      setDropdownOptions(newDropdown);
    } catch (error) {
      console.log("Error fetching dropdown options:", error);
    }
  };

  useEffect(() => {
    // fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  To open sibebarform
  const handleAssignUser = async (action, item) => {
    fetchAllUsers();
    if (action === "edit") {
      try {
        const userData = await axiosInstance.get(
          AppSettings.URLs.getUserById + item.assignedToUserId
        );
        setUserId(userData?.data?.response?.user[0]?.empid);
      } catch (error) {
        console.log(error);
      }
    }
    setInputValues({
      assetid: data.data.id,
      assetType: data.asset,
      assetName: data.data.systemId,
      userId: item?.assignedToUserId,
      username: item?.assignedTo,
      assignedOn: data.data?.assignedOn
        ? data.data?.assignedOn?.substring(0, 10)
        : new Date(),
    });
    setAllocateSelectedValue({
      assetid: { value: data.data?.id, label: data.data?.id },
      userid: {
        value: data.data.assignedToUserId,
        label: data.data.assignedToUserId,
      },
      username: {
        value: data.data.assignedToUserId,
        label: data.data.assignedTo ? data.data.assignedTo : "Select User",
      },
      assetType: { value: data.asset, label: data.asset },
      assetName: { value: data.data?.systemId, label: data.data?.systemId },
    });
    setIsAllocateFormOpen(true);
  };

  // To handle the input form the user
  const handleInputChange = async (e, name) => {
    try {
      const userData = await axiosInstance.get(
        AppSettings.URLs.getUserById + e[0]?.value
      );
      setUserId(userData?.data?.response?.user[0]?.empid);
    } catch (error) {
      console.log(error);
    }
    if (e[0]?.label === "No User") {
      setUserId("");
    }
    let value;
    if (e && e.target) {
      // Regular input field
      value = e.target?.value;
      setAllocateSelectedValue((option) => ({
        ...option,
        [name]: { value: null, label: value },
      }));
    } else {
      // React Select component
      value = e ? e[0]?.label : "";
      setAllocateSelectedValue((option) => ({
        ...option,
        [name]: { value: e ? e[0]?.value : "", label: e ? e[0]?.label : "" },
      }));
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  // To close the sidebarform
  const handleAssignUserformClose = () => {
    // fetchAllUsers();
    setInputValues({});
    setIsAllocateFormOpen(false);
    setUserId("");
    setDataError({ username: false, assignedOn: false });
    // setAllocateSelectedValue({
    //   assetid: { value: data.data?.id, label: data.data?.id },
    //   userid: {
    //     value: data.data.assignedToUserId,
    //     label: data.data.assignedToUserId,
    //   },
    //   username: {
    //     value: data.data.assignedToUserId,
    //     label: data.data.assignedTo ? data.data.assignedTo : "User Name",
    //   },
    //   assetType: { value: data.asset, label: data.asset },
    //   assetName: { value: data.data?.systemId, label: data.data?.systemId },
    // });
  };

  // To submit the sidebar form
  const handleFormSubmit = () => {
    let isFormFilled = false;
    setDataError({
      username:
        inputValues?.username === undefined || inputValues.username === null
          ? true
          : false,
    });
    if (inputValues?.username !== undefined && inputValues?.username !== null) {
      if (inputValues.assignedOn > dateToFormat.toISOString().split("T")[0]) {
        setDataError((dataerror) => ({
          ...dataerror,
          assignedOn: true,
        }));
        toast.error("Can't be greater than today date!", {
          toastId: customId,
        });
      } else {
        setDataError((dataerror) => ({
          ...dataerror,
          assignedOn: false,
        }));
        isFormFilled = true;
      }
    }

    if (isFormFilled === true) {
      if (data.data.assignedTo === null || data.data.assignedTo === "") {
        try {
          axiosInstance
            .put(AppSettings.URLs.assetAllocate, inputValues)
            .then(() => {
              data.getAsset(data.asset);
              // fetchAllUsers();
              toast.success("Updated Successfully", {
                toastId: customId,
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
        handleAssignUserformClose();
        setInputValues({});
      } else {
        try {
          axiosInstance
            .put(AppSettings.URLs.updateAssetAllocate, inputValues)
            .then(() => {
              data.getAsset(data.asset);
              // fetchAllUsers();
              toast.success("Updated Successfully", {
                toastId: customId,
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
        handleAssignUserformClose();
        setInputValues({});
      }

      //     if(data.data.assignedTo===null||data.data.assignedTo===""){
      //       // if(data.asset==="Monitor"&&userDetails.monitor1!==null&&userDetails.monitor2!==null&&userDetails.monitor1!==""&&userDetails.monitor2!==""){
      //       //   toast.error(`Two monitors already assigned to ${userDetails.username}`)
      //       // }else{
      //     try {
      //       axios
      //         .put(AppSettings.URLs.assetAllocate, inputValues)
      //         .then(() => {
      //           data.getAsset(data.asset)
      //               fetchAllUsers()
      //           toast.success("Updated Successfully");
      //         })
      //         .catch(error => {
      //           console.log(error);
      //           toast.error("Something went wrong");
      //         });
      //     } catch (error) {
      //       console.log(error);
      //       toast.error("Something went wrong");
      //     }
      //     handleAssignUserformClose();
      //     setInputValues({});
      //      }
      //   else if(data.data.assignedTo!==null){
      //     if(data.asset==="Monitor"&&userDetails.monitor1!==null&&userDetails.monitor2!==null&&userDetails.monitor1!==""&&userDetails.monitor2!==""){
      //       toast.error(`Two monitors already assigned to ${userDetails.username}`)
      //   }
      //   else{
      //     try {
      //       axios
      //         .put(AppSettings.URLs.updateAssetAllocate, inputValues)
      //         .then(() => {
      //           data.getAsset(data.asset);
      //               fetchAllUsers()
      //           toast.success("Updated Successfully");
      //         })
      //         .catch(error => {
      //           console.log(error);
      //           toast.error("Something went wrong");
      //         });
      //     } catch (error) {
      //       console.log(error);
      //       toast.error("Something went wrong");
      //     }
      //     handleAssignUserformClose();
      //     setInputValues({});
      //   }
      // }
    } else {
      toast.error("Please fill all fields", {
        toastId: customId,
      });
    }
  };

  // to get user by ID
  const checkForDualMon = async (id) => {
    try {
      const userData = await axiosInstance.get(
        AppSettings.URLs.getUserById + id
      );
      setUserDetails(
        userData?.data?.response?.user ? userData?.data?.response?.user[0] : ""
      );
      setUserId(userData?.response?.user[0]?.empid);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button
        className={`btn btn-sm border-radius-4 d-flex align-items-center justify-content-center btn-secondary whitespace-nowrap ${
          data.data.assignedTo === null || data.data.assignedTo === ""
            ? ""
            : "!hidden"
        } ${
          data.data.isActive
            ? "cursor-pointer"
            : "!cursor-not-allowed opacity-60"
        }`}
        onClick={() => {
          data.data.isActive && handleAssignUser("add", null);
        }}
      >
        Assign User
      </button>
      <button
        className={`btn btn-sm border-radius-4 d-flex align-items-center justify-content-center btn-primary whitespace-nowrap ${
          data.data.assignedTo === null || data.data.assignedTo === ""
            ? "!hidden"
            : ""
        }`}
        onClick={() => handleAssignUser("edit", data.data)}
      >
        Update User
      </button>

      {/* to open sidebar form (asset allocat to user) */}

      <div
        className={`kr-popup-overlay ${
          isAllocateformOpen ? "kr-side-popup-overlay" : ""
        }`}
      ></div>
      <div
        className={`kr-side-popup ${
          isAllocateformOpen ? "kr-side-popup-on " : ""
        }`}
      >
        <div className="kr-block w-100">
          <div className="flex items-center py-[16px] px-6 bg-gray-50">
            <div className="pl-2">
              <h2 className="font-bold color-dark text-[14px] mb-0">
                Allocate asset
              </h2>
              <span className="text-gray-400 text-[12px]">
                Allocat asset to user
              </span>
            </div>
          </div>
          <div className="kr-block p-4">
            <div id="form">
              <div className="grid gap-x-4 !grid-cols-1 ">
                <div className="col-span-2">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Asset Type</label>
                    <input
                      type="text"
                      placeholder="Asset type"
                      name="AssetType"
                      value={[allocateSelectedValue?.assetType.label]}
                      className={`form-control kr-form-textbox `}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Asset Name</label>
                    <input
                      type="text"
                      placeholder="Asset Name"
                      name="AssetName"
                      value={[allocateSelectedValue?.assetName.label]}
                      className={`form-control kr-form-textbox `}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Select User</label>
                    <Select
                      backspaceDelete={false}
                      placeholder="Select User"
                      name="username"
                      values={[allocateSelectedValue?.username]}
                      className={`form-control kr-form-textbox placeholder-[#212529]  ${
                        dataError.username === false ? "" : "dataError"
                      }`}
                      options={dropdownOptions?.map((option) => ({
                        value: option?.id,
                        label: option?.username,
                      }))}
                      onChange={(selectedOption) => {
                        handleInputChange(selectedOption, "username");
                        // checkForDualMon(selectedOption[0]?.value);
                        setInputValues((prevInputValues) => ({
                          ...prevInputValues,
                          userId: selectedOption[0]?.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="kr-selectform">
                    <label className="kr-form-label">User ID</label>
                    <input
                      type="text"
                      placeholder="User ID"
                      name="userid"
                      value={userId}
                      className={`form-control kr-form-textbox placeholder-[#212529] `}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="kr-selectform">
                    <label className="kr-form-label">Assigned Date</label>
                    <input
                      type="date"
                      data-date-format="DD MMMM YYYY"
                      placeholder="Allocated Date"
                      name="userid"
                      value={inputValues?.assignedOn}
                      onChange={(options) => {
                        setInputValues((prevalues) => ({
                          ...prevalues,
                          assignedOn: options.target.value,
                        }));
                      }}
                      className={`form-control  ${
                        dataError.assignedOn === false ? "" : "dataError"
                      } kr-form-textbox placeholder-[#212529] `}
                    />
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="kr-selectform flex justify-end">
                    <button
                      type="button"
                      className="flex items-center justify-center py-[6px] px-[12px] btn-light border border-solid border-gray-200 rounded min-w-80"
                      onClick={handleAssignUserformClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary flex items-center justify-center ml-2 min-w-80"
                      onClick={handleFormSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer transition={Slide} /> */}
    </>
  );
}

export default AllcoateAsset;
