import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AssetManagement from "./components/assset-management/asset-management";
import SystemManagement from "./components/system-management/system-management";
import Master from "./components/master/master";
import UserManagement from "./components/user-mangement/user-management";
import Report from "./components/reports/report";
import LoginPage from "./components/login-page/LoginPage";
import { useParams, useLocation } from "react-router-dom";

function RouteContent({ submit, setSubmit }) {
  const location = useLocation();
  const currentPath = location.pathname;
  const user = localStorage.getItem("accesstoken")
  const refresh = localStorage.getItem("refreshtoken")
  return (
    <>
      <div id="app__content" className={`${currentPath === "/" ? "!p-0" : ""}`}>
        <Routes>
          {/* <Route path="/" element={<Master />} /> */}
          <Route path="/master" element={<Master />} />
          <Route
            path="/"
            element={
              user&&refresh? <Navigate replace to ="/master"/>:<LoginPage setSubmit={setSubmit} submit={submit}  ></LoginPage>
            }
          />
          <Route path="/asset-management" element={<AssetManagement />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/system-management" element={<SystemManagement />} />
          <Route path="/report" element={<Report />} />
        </Routes>
      </div>
    </>
  );
}

export default RouteContent;
