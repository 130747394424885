import { useState } from "react";
import sysforeLogoLight from "../assets/images/Sysfore-logo-light.svg";
import sysforeLogoDark from "../assets/images/Sysfore-logo-dark.svg";
import { toast } from "react-toastify";
import "./LoginPage.css";
import { AppSettings } from "../../constant";
import axiosInstance from "../../axios-interceptor";
import { useNavigate } from "react-router-dom";
import { setLoginData } from "../../axios-interceptor";

function LoginPage({ submit, setSubmit }) {
  const [login, setLogin] = useState({
    username: "",
    password: "",
    granttype: "password",
  });

  const navigate = useNavigate();
  const customId = "custom-id-yes";

  const [data, setData] = useState(null);

  const handleLoginSubmit = async () => {
    try {
      const res = await axiosInstance.post(AppSettings.URLs.login, login);
      if (res.status === 200 && res.data.response?.token) {
        setData(res.data.response);
        toast.success("Successfully Logged in", {
          toastId: customId,
        });
        navigate("/master", { replace: true });
        setTimeout(() => {
          setSubmit(!submit);
        }, 700);
        localStorage.setItem(
          "accesstoken",
          res.data.response.token.accesstoken
        );

        localStorage.setItem(
          "username",
          res.data.response.token.userdata.username
        );
        localStorage.setItem(
          "refreshtoken",
          res.data.response.token.refreshtoken
        );
        const { granttype, ...rest } = login;
        setLoginData(rest);
      } else {
        toast.error("Login credentials is not correct", {
          toastId: customId,
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <div className=" total-page relative min-h-full font-['Rubik','arial'] ">
      <div id="page_body" className=" w-full pt-0 pr-0 pb-0 ps-0 min-h-screen">
        <div className=" relative min-h-full h-full">
          <div className="min-h-screen w-full flex-col flex">
            <div className="flex min-h-screen">
              <div className=" w-3/5 sys-primary-bg p-10 sys-login-bg relative hidden lg:block md:hidden">
                <div className=" w-full z-10 relative">
                  <div className=" w-full !mb-5 ">
                    <div className=" block p-0 m-0 h-14 lg:w-52 md:w-52 sm:w-36 shrink-0">
                      <a href="#" className="flex items-center">
                        <img
                          className=" w-40 h-14"
                          src={sysforeLogoLight}
                          alt="sysfore-logo"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="mb-10 w-6/12">
                    <h2 className=" text-3xl text-white !mb-3 font-family-light font-extralight  ">
                      Welcome to your Asset management.
                    </h2>
                    <p className="text-sm text-white/50 m-0 p-0 text-[.875rem] font-extralight  ">
                      Sysfore is a leading professional services company,
                      providing a broad range of smart services and solutions in
                      Consulting, Technology and Managed Services
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-2/5 md:w-full w-full bg-white flex-col flex justify-center items-center p-10 ">
                <div className="w-full lg:w-80 md:w-80 sm:w-80 ">
                  <div className="w-full mb-[20px] block lg:hidden">
                    <div className=" block p-0 m-0 h-14 w-52 lg:w-52 sm:w-36 md:w-52 shrink-0">
                      <a href="#" className="flex items-center">
                        <img src={sysforeLogoDark} className="w-40 h-14" />
                      </a>
                    </div>
                  </div>
                  <div className=" sys-login-title w-full relative pb-6">
                    <h2 className=" text-lg text-gray-900 font-bold ">Login</h2>
                    <span className="text-xs text-slate-400">
                      Hey, Enter your details to log in to your account.
                    </span>
                  </div>
                  <div className="w-full mt-8">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleLoginSubmit();
                      }}
                    >
                      <div className="mb-[16px]">
                        <label
                          for="username"
                          className="block text-gray-800 text-xs mb-2 font-medium "
                        >
                          Username
                        </label>
                        <input
                          onChange={(e) => {
                            setLogin((value) => ({
                              ...value,
                              username: e.target.value,
                            }));
                          }}
                          id="username"
                          type="text"
                          placeholder="Enter Username"
                          className="h-11 sys-form-textbox appearance-none border-2 border-slate-200 rounded w-full !py-2 !px-4 text-gray-800 text-xs leading-tight focus:outline-none focus:border-orange-400 sys-textbox-box-shadow "
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          for="password"
                          className="block text-gray-800 text-xs mb-2 font-medium "
                        >
                          Password
                        </label>
                        <input
                          onChange={(e) => {
                            setLogin((value) => ({
                              ...value,
                              password: e.target.value,
                            }));
                          }}
                          id="password"
                          type="password"
                          placeholder="Enter Password"
                          className="h-11 sys-form-textbox appearance-none border-2 border-slate-200 rounded w-full !py-2 !px-4 text-gray-800 text-xs leading-tight focus:outline-none focus:border-orange-400 sys-textbox-box-shadow "
                        ></input>
                      </div>
                      <div className=" flex items-center justify-between mb-[12px]">
                        <button
                          type="submit"
                          className=" font-medium  w-full h-11 bg-orange-500 hover:bg-orange-500/90 transition text-white text-xs py-2 px-4 rounded focus:outline-none  focus:shadow-outline "
                        >
                          Login your account
                        </button>
                      </div>
                    </form>
                    <div className="text-xs text-right">
                      <span className=" text-gray-700">
                        Forgot Password?
                        <a
                          href="#"
                          className="inline-block align-baseline ps-1 text-xs text-gray-800 hover:text-orange-500 font-medium "
                        >
                          Click Here
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer transition={Slide} /> */}
    </div>

    // 	<div className=" !h-screen w-full">
    //   <div className="grid grid-cols-[60%,40%] overflow-hidden">

    //   <div id="left-side-login" className="bg-green-100 !h-screen p-10">
    // 	<div className=" w-full">
    // 		<div className="w-full mb-5" >
    // 			<div className=" block p-0 m-0 h-14 w-52 lg:w-52 md:w-52 sm:w-36 shrink-0 ">
    // 				<a href="#" className=" flex items-center">
    // 					<img className="w-40 h-14" src={sysforeLogo}  alt="Sysfore-logo"/>
    // 				</a>
    // 			</div>
    // 		</div>
    // 		<div className="w-[50%] mb-10">
    // 		  <h2 className="text-3xl text-black mb-3">Welcome to Your HR System.</h2>
    // 		  <p className="text-sm text-black/50 m-0 p-0">Sysfore is a leading professional services company, providing a broad range of smart services and solutions in Consulting, Technology and Managed Services</p>
    // 		</div>
    // 	</div>
    //   </div>

    //   <div className=" bg-yellow-200 !h-screen  ">
    //   </div>

    //   </div>
    // 	</div>
    //
  );
}

// function LoginPage({submit,setSubmit}) {

// 	// let [nameToggle,setNameToggle]=useState(false)
// 	let [passwordToggle,setPasswordToggle]=useState(false)
// 	let [passwordIconShow,setPasswordIconShow]=useState(false)
//     return (
// 	<div className=" !h-screen w-full">
//       <div className="grid grid-cols-[35%,75%] overflow-hidden  ">

//         <div className="bg-white !h-screen  ">
// 			<div className=" h-full flex flex-col justify-center  ">
//                 <div className="">
//                   <svg className="sysfore-logo w-[200px] m-auto bg-[#000080] " version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
// 	 viewBox="0 0 566 129.4" >

// <g id="Dark">
// 	<path className="st0" d="M31.6,98.3c-10.2,0-18.4-1.4-24.7-3.2c-1.2-0.4-2.5-1.1-2.3-2.5l2.2-15.3c0.2-1.6,1.5-2.5,2.7-2.5
// 		c0.1,0,0.1,0,0.2,0c5.2,0.5,18.4,1.9,25,1.9c6.1,0,8.2-2.4,8.7-6.4c0.4-2.5-0.7-3.9-4.9-6.6L22.1,53.1
// 		C11.4,46.3,9.4,37.8,10.8,28.2c1.9-13.6,11-24.8,36.1-24.8c9.8,0,22.9,1.6,27.8,3.1c1.1,0.4,2.1,1,1.9,2.4l-2.2,15.6
// 		c-0.2,1.2-1.2,2.4-2.6,2.4c-0.1,0-0.1,0-0.2,0c-5.1-0.4-19.1-1.6-28-1.6c-4.6,0-6.5,1.6-6.8,4.2c-0.3,2.2,0.6,3.5,5.6,6.4l15.6,9
// 		c11.2,6.5,14.4,14.7,12.9,25C68.9,83.7,57.3,98.3,31.6,98.3z"/>
// 	<path className="st0" d="M166.7,7.2l-24.3,43c-3.5,6.1-6.8,9.3-10.3,10.7l-4.7,33.7c-0.2,1.4-1.5,2.5-2.8,2.5h-20.3
// 		c-1.4,0-2.2-1.1-2-2.5l4.7-33.7c-3.2-1.4-5.6-4.6-7.3-10.7l-12.3-43c-0.1-0.4-0.2-0.6-0.1-1c0.1-0.9,0.9-1.6,2-1.6h22.5
// 		c1.2,0,1.8,1.1,2.2,2.4l7.3,33c0.2,1,0.7,1.2,1.1,1.2c0.4,0,0.9-0.2,1.4-1.2l16.6-33c0.7-1.2,1.6-2.4,2.8-2.4h22.5
// 		c1.1,0,1.6,0.7,1.5,1.6C167,6.6,166.9,6.9,166.7,7.2z"/>
// 	<path className="st0" d="M192.1,98.3c-10.2,0-18.4-1.4-24.7-3.2c-1.2-0.4-2.5-1.1-2.3-2.5l2.2-15.3c0.2-1.6,1.5-2.5,2.7-2.5
// 		c0.1,0,0.1,0,0.2,0c5.2,0.5,18.4,1.9,25,1.9c6.1,0,8.2-2.4,8.7-6.4c0.4-2.5-0.7-3.9-4.9-6.6l-16.4-10.6
// 		c-10.7-6.8-12.7-15.3-11.3-24.9c1.9-13.6,11-24.8,36.1-24.8c9.8,0,22.9,1.6,27.8,3.1c1.1,0.4,2.1,1,1.9,2.4L235,24.4
// 		c-0.2,1.2-1.2,2.4-2.6,2.4c-0.1,0-0.1,0-0.2,0c-5.1-0.4-19.1-1.6-28-1.6c-4.6,0-6.5,1.6-6.8,4.2c-0.3,2.2,0.6,3.5,5.6,6.4l15.6,9
// 		c11.2,6.5,14.4,14.7,12.9,25C229.4,83.7,217.8,98.3,192.1,98.3z"/>
// 	<path className="st0" d="M305.8,25.1h-25.2c-4.5,0-6.2,1.4-6.9,6.2l-1.3,9H303c1.4,0,2.3,1.1,2.1,2.5L303,57.5
// 		c-0.2,1.4-1.5,2.5-2.8,2.5h-30.6l-4.9,34.6c-0.2,1.4-1.5,2.5-2.8,2.5h-20.3c-1.4,0-2.3-1.1-2.1-2.5l8.9-63.3
// 		c3.4-24,19.9-27.9,35.8-27.9c12.1,0,19.7,0.9,24.3,1.5c1.6,0.2,2.4,1,2.1,2.5l-2.1,15.2C308.4,24,307.1,25.1,305.8,25.1z"/>
// 	<path className="st0" d="M341.5,67.9c1,5.6,4.3,8,10.2,8c8.7,0,13.3-5.4,15.2-18.7l1.8-12.8c1.9-13.3-1.2-18.8-9.9-18.8
// 		c-5.7,0-9.6,2.3-12.2,7.6l-20.8-11.7c8.4-13.1,21.9-18.2,36.1-18.2c20.7,0,36.7,10.7,32.5,41.1l-1.8,12.8c-4.3,30.4-23.3,41-44,41
// 		c-14.4,0-26.5-5.1-31.2-18.5L341.5,67.9z"/>
// 	<path className="st0" d="M474.5,97.2h-22.9c-1.4,0-1.7-1.2-2.1-2.5l-11.4-29.9l-0.8-0.5c-1.2,0-5.2-0.1-6.5-0.1l-4.3,30.5
// 		c-0.2,1.4-1.5,2.5-2.8,2.5h-20.2c-1.4,0-2.5-1.1-2.3-2.5l11.4-81.4c0.8-6,3-7.3,8.4-8.2c5.1-0.7,17.7-1.6,26.5-1.6
// 		c23.2,0,37.9,7.3,34.7,30.1l-0.3,2c-1.9,13.3-10.3,21.2-18.6,24.5L476.6,94c0.2,0.5,0.2,1,0.2,1.4
// 		C476.7,96.4,475.7,97.2,474.5,97.2z M457.2,33.5c0.8-5.9-2.9-8.8-11.2-8.8c-0.9,0-8.9,0.2-9.6,0.2l-2.8,19.8
// 		c0.6,0.1,8.3,0.1,9.1,0.1c9.6,0,13.4-3.4,14.2-9.2L457.2,33.5z"/>
// 	<path className="st0" d="M544.8,96.8c-4.8,0.6-12.7,1.5-24.7,1.5c-15.9,0-31.4-3.7-28-27.9l5.5-39.1c3.4-24,19.9-27.9,35.8-27.9
// 		c12.1,0,19.7,0.9,24.3,1.5c1.6,0.2,2.4,1,2.1,2.5l-2.1,15.2c-0.2,1.4-1.5,2.5-2.8,2.5h-25.2c-4.5,0-6.2,1.4-6.9,6.2l-1.3,9.1h30.6
// 		c1.4,0,2.3,1.1,2.1,2.5l-2.1,14.7c-0.2,1.4-1.5,2.5-2.8,2.5h-30.6l-1.5,10.3c-0.7,4.9,0.6,6.2,5.1,6.2h25.2c1.4,0,2.3,1.1,2.1,2.5
// 		l-2.1,15.2C547.4,95.8,546.4,96.5,544.8,96.8z"/>
// 	<path className="st1" d="M323.3,26.5c0,0-3.1,5.1-4.8,17.2l-1.8,14.6c0,0-1.1,9-0.2,15.7l44.1-20.6c2.8-1.3,2.9-5.3,0.2-6.7L323.3,26.5
// 		z"/>
// 	<g>
// 		<path className="st2" d="M243,117h-3.7v-1.8h9.5v1.8H245v12.1h-2V117z"/>
// 		<path className="st2" d="M267.2,115.2h8.4v1.7h-6.4v3.9h5.6v1.8h-5.6v4.5h6.6v1.9h-8.6V115.2z"/>
// 		<path className="st2" d="M302.7,118.3h-2.1c0-0.5-0.2-0.9-0.5-1.2s-0.7-0.4-1.2-0.4c-0.8,0-1.4,0.5-1.8,1.4s-0.6,2.2-0.6,3.9
// 			c0,1.8,0.2,3.2,0.6,4.1s1,1.4,1.8,1.4c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1h2.1c-0.1,1.1-0.5,1.9-1.2,2.5
// 			s-1.5,0.9-2.5,0.9c-1.5,0-2.7-0.6-3.5-1.9c-0.8-1.3-1.2-3.1-1.2-5.5c0-2.2,0.4-3.9,1.3-5.1c0.9-1.2,2-1.9,3.5-1.9
// 			c1,0,1.9,0.3,2.5,0.9C302.2,116.4,302.6,117.3,302.7,118.3z"/>
// 		<path className="st2" d="M321.2,115h2v5.9h4.9V115h2v14.1h-2v-6.4h-4.9v6.4h-2V115z"/>
// 		<path className="st2" d="M348.2,115h2.4l5,10.5V115h1.9v14.1h-2.3l-5.1-10.6v10.6h-1.9V115z"/>
// 		<path className="st2" d="M385.1,122.1c0,2.1-0.5,3.9-1.4,5.3s-2.2,2-3.6,2s-2.6-0.7-3.6-2c-1-1.4-1.4-3.1-1.4-5.2
// 			c0-2.1,0.5-3.9,1.4-5.3c1-1.4,2.2-2,3.6-2c1.5,0,2.7,0.7,3.6,2S385.1,119.9,385.1,122.1z M380.1,127.5c0.9,0,1.5-0.5,2.1-1.5
// 			c0.5-1,0.8-2.3,0.8-4s-0.3-3-0.8-4s-1.2-1.5-2.1-1.5s-1.6,0.5-2.1,1.4s-0.8,2.3-0.8,4s0.3,3,0.8,4
// 			C378.5,127,379.2,127.5,380.1,127.5z"/>
// 		<path className="st2" d="M403.5,115h2v12.1h7.1v1.9h-9.1V115z"/>
// 		<path className="st2" d="M439.5,122.1c0,2.1-0.5,3.9-1.4,5.3c-0.9,1.4-2.2,2-3.6,2s-2.6-0.7-3.6-2c-1-1.4-1.4-3.1-1.4-5.2
// 			c0-2.1,0.5-3.9,1.4-5.3c1-1.4,2.2-2,3.6-2c1.5,0,2.7,0.7,3.6,2C439,118.2,439.5,119.9,439.5,122.1z M434.5,127.5
// 			c0.9,0,1.5-0.5,2.1-1.5c0.5-1,0.8-2.3,0.8-4s-0.3-3-0.8-4s-1.2-1.5-2.1-1.5s-1.6,0.5-2.1,1.4c-0.5,1-0.8,2.3-0.8,4s0.3,3,0.8,4
// 			C432.9,127,433.6,127.5,434.5,127.5z"/>
// 		<path className="st2" d="M461.4,121.6h5.1v6.9c-0.9,0.3-1.6,0.5-2.3,0.6c-0.6,0.1-1.2,0.2-1.7,0.2c-1.8,0-3.1-0.6-4.1-1.9
// 			c-1-1.3-1.5-3.1-1.5-5.4c0-2.2,0.5-4,1.5-5.3s2.4-1.9,4.1-1.9c1.1,0,1.9,0.3,2.6,0.8s1.1,1.2,1.3,2.1h-2.1
// 			c-0.2-0.4-0.5-0.6-0.8-0.8s-0.6-0.3-1-0.3c-1.2,0-2,0.4-2.6,1.4s-0.9,2.2-0.9,4s0.3,3.2,0.9,4.1c0.6,0.9,1.4,1.4,2.6,1.4
// 			c0.3,0,0.6,0,0.9-0.1s0.7-0.2,1.1-0.3v-3.6h-3v-1.9H461.4z"/>
// 		<path className="st2" d="M485.4,127.2h2.5V117h-2.1v-1.8h6.2v1.8h-2.1v10.2h2.6v1.9h-7.1V127.2z"/>
// 		<path className="st2" d="M512.1,115.2h8.4v1.7h-6.4v3.9h5.6v1.8h-5.6v4.5h6.6v1.9h-8.6V115.2z"/>
// 		<path className="st2" d="M545.5,118.5c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.5-1.5-0.5s-1.1,0.2-1.5,0.5s-0.6,0.8-0.6,1.3
// 			c0,0.4,0.2,0.8,0.5,1.2s1,0.8,1.9,1.2l0.8,0.4c1.2,0.6,2.1,1.2,2.6,1.9s0.7,1.4,0.7,2.3c0,1.2-0.4,2.1-1.3,2.8c-0.8,0.7-2,1-3.4,1
// 			c-1.3,0-2.3-0.3-3-1s-1.1-1.7-1.1-3h2.2v0.1c0,0.7,0.2,1.2,0.5,1.6c0.4,0.4,0.9,0.6,1.5,0.6c0.7,0,1.3-0.2,1.8-0.6
// 			c0.5-0.4,0.7-0.9,0.7-1.5c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.4-1.1-0.8-2.3-1.4l-1-0.5c-0.9-0.4-1.6-1-2.1-1.6s-0.7-1.3-0.7-2.1
// 			c0-1.1,0.4-2,1.2-2.7s1.9-1,3.2-1c1.2,0,2.2,0.3,2.9,1c0.7,0.6,1.1,1.5,1.2,2.7L545.5,118.5L545.5,118.5z"/>
// 	</g>
// </g>
//                   </svg>
//                 </div>
//                    {console.log(passwordIconShow)}
//                  <div className=" text-lg text-gray-500 text-center pt-[3.5rem] mb-[1.4rem] ">
//                         <h4 className=" pb-[1rem] text-black font-semibold">LOGIN</h4>
//                         <p>Hey,Enter your details to login</p>
//                         <p>to your account.</p>
//                  </div>

//                  <div>
//                     <form className="">
//                         <div className=" username-container  w-full  !flex mb-[1.4rem] justify-center items-center gap-x-[1rem]">
//                         <input placeholder="Enter Username" onFocus={(e)=>{ e.stopPropagation()
// 							 setPasswordToggle(true)}} onBlur={(e)=>{
// 								e.stopPropagation()
// 							 setPasswordToggle(false)}}  className=" username-input w-[75%] h-[47px] rounded-md border-solid border-1 !border-b-gray-200 outline-none m-0 " type="text"/>
//                         <label className={passwordToggle?"username-label":"username-label-hide"}>Username</label>
//                         </div>

//                         <div className=" password-container w-full  !flex mb-[1.4rem] justify-center items-center gap-x-[1rem]">
//                         <input placeholder="Enter Password"  onFocus={(e)=>{
// 							e.stopPropagation()
// 							console.log("im getting calles")
// 							setPasswordToggle(true)}} onBlur={(e)=> {
// 							e.stopPropagation()
// 								setPasswordToggle(false)} } className=" password-input w-[75%] h-[47px] rounded-md border-solid border-1 !border-b-gray-200 outline-none m-0 " type={passwordIconShow?"text":"password"}/>
//                         <label className={passwordToggle?"password-label":"password-label-hide"}>Password</label>
// 						 <img onClick={(e)=>{
//                              e.stopPropagation()
// 							setPasswordIconShow(!passwordIconShow)
// 							}} className=" password-icon  h-[20px] w-[20px] self-center" src={!passwordIconShow?passwordHide:passwordShow} alt="password-toggle-icon"/>
//                         </div>

//                         <div className=" w-full flex justify-center items-center mb-[1.4rem]">
//                           <button onClick={()=>setSubmit(!submit)} className=" bg-orange-600 text-white w-[75%] h-[47px] text-lg rounded-md ">Login</button>
//                         </div>
//                     </form>

//                     <p className=" text-center text-[0.94rem]">Forgot Password? <span className=" text-orange-600 cursor-pointer ">Click here</span></p>
//                  </div>
// 				 </div>
//         </div>

//         <div className=" bg-[#000080] !h-full "></div>

//       </div>
// 	  </div>
//     );
//   }

export default LoginPage;
