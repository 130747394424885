import React from "react";
import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ExcelExportButton({ data, asset }) {
  const customId = "custom-id-yes";
  let apiData = data?.map((value) => {
    if (value?.createdOn?.length > 0) {
      value.createdOn = value.createdOn.split("T")[0];
    }

    if (value?.purchaseDate?.length > 0) {
      value.purchaseDate = value.purchaseDate.split("T")[0];
    }

    if (value?.warrantyTill?.length > 0) {
      value.warrantyTill = value.warrantyTill.split("T")[0];
    }
    const { id, isDelete, changeInRemarks, ...rest } = value;
    return rest;
  });

  const handleExportClick = () => {
    if (data?.length === 0) {
      toast.error("No data is there to export", {
        toastId: customId,
      });
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Define your custom header styling here
    const headerStyle = {
      font: {
        name: "Arial",
        bold: true,
        color: { argb: "FFFFFF" }, // White text color
        size: 10,
      },
      alignment: {
        horizontal: "center", // Center horizontally
        vertical: "middle", // Center vertically
        wrapText: false,
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4f81bd" }, // Black background color
      },
    };

    // Define your custom data row styling here
    const dataCellStyle = {
      alignment: {
        horizontal: "left",
        vertical: "middle",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { argb: "0000" } },
        left: { style: "thin", color: { argb: "0000" } },
        bottom: { style: "thin", color: { argb: "0000" } },
        right: { style: "thin", color: { argb: "0000" } },
      },
    };

    // leave the first three row
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);

    const headingStyle = {
      font: {
        name: "Arial",
        bold: true,
        color: { argb: "0000" }, // White text color
        size: 15,
      },
      alignment: {
        horizontal: "center", // Center horizontally
        vertical: "middle", // Center vertically
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { argb: "0000" } },
        left: { style: "thin", color: { argb: "0000" } },
        bottom: { style: "thin", color: { argb: "0000" } },
        right: { style: "thin", color: { argb: "0000" } },
      },
    };

    let borderstyle = {
      border: {
        top: { style: "thin", color: { argb: "0000" } },
        left: { style: "thin", color: { argb: "0000" } },
        bottom: { style: "thin", color: { argb: "0000" } },
        right: { style: "thin", color: { argb: "0000" } },
      },
    };

    // for styling main heading
    worksheet.mergeCells("E1", "H2");
    const cellsToStyle = ["E1", "E2", "F1", "F2", "G1", "G2", "H1", "H2"];
    if (asset === "CPU") {
      worksheet.getCell("E1").value = " Desktop's Informations";
    } else {
      worksheet.getCell("E1").value = `${asset}'s Informations`;
    }

    cellsToStyle.forEach((cells) => {
      const cell = worksheet.getCell(cells);
      cell.style = headingStyle;
    });

    /// for cpu and laptop
    let headings = [
      "System Id",
      "Product No",
      "Serial No",
      "Mac Id",
      "Bios Version",
      "Brand",
      "Ram",
      "Processor",
      "Generation",
      "Storage Type",
      "Storage Size",
      "Windows Os",
      "Model No",
      "User Assigned",
      "Purchase Date",
      "Under Warranty",
      "Warranty Till",
      "Assigned To",
      "Assigned To Previous",
      "Assigned To UserId",
      "State",
      "Created By",
      "Created On",
      "Monitor Assigned",
      "No Of Monitor ",
      "Keyboard Assigned",
      "Mouse Assigned",
      "HeadSet Assigned",
      "Is AV Installed",
      "Windows License",
      "Is Licensed",
      "Ip Address",
      "Remarks",
      "No Of Months Old",
      "Updated By",
      "Updated On",
    ];

    /// for monitor alone
    let headings2 = [
      "System Id",
      "Product No",
      "Serial No",
      "Brand",
      "Screen Size",
      "User Assigned",
      "Purchase Date",
      "Under Warranty",
      "Warranty Till",
      "Assigned To",
      "Assigned To Previous",
      "Assigned To User Id",
      "User Active",
      "Created By",
      "Created On",
      "Remarks",
      "No Of Months Old",
      "Updated By",
      "Updated On",
    ];

    ///for network
    let headings3 = [
      "System Id",
      "Product No",
      "Asset Type",
      "Model",
      "Serial No",
      "Ram",
      "License",
      "Disk",
      "Os",
      "Purchase Date",
      "Under Warranty",
      "Warranty Till",
      "User Assigned",
      "Assigned To",
      "Assigned To Previous",
      "Assigned To User Id",
      "User Active",
      "Network Port",
      "Imei",
      "Processor",
      "Location",
      "Created By",
      "Created On",
      "Remarks",
      "Updated By",
      "Updated On",
    ];

    ///for otherAsset
    let headings4 = [
      "System Id",
      "Product No",
      "Asset Type",
      "Model",
      "Serial No",
      "License",
      "Imei",
      "Wired Type",
      "Purchase Date",
      "Warranty Till",
      "Is Assigned",
      "Assigned To",
      "Assigned To Previous",
      "Assigned To User Id",
      "Is Active",
      "Created By",
      "Created On",
      "Remarks",
      "Updated By",
      "Updated On",
    ];

    // const headerRow = worksheet.addRow(Object.keys(data[0])); // Replace with your column names

    if (asset === "CPU" || asset === "Laptop") {
      const headerRow = worksheet.addRow(headings);
      headerRow.eachCell((cell) => {
        cell.style = headerStyle;
      });
    } else if (asset === "Monitor") {
      const headerRow = worksheet.addRow(headings2);
      headerRow.eachCell((cell) => {
        cell.style = headerStyle;
      });
    } else if (asset === "Network") {
      const headerRow = worksheet.addRow(headings3);
      headerRow.eachCell((cell) => {
        cell.style = headerStyle;
      });
    } else {
      const headerRow = worksheet.addRow(headings4);
      headerRow.eachCell((cell) => {
        cell.style = headerStyle;
      });
    }

    apiData.forEach((item) => {
      const values = Object.values(item);

      const row = worksheet.addRow([]);

      values.forEach((value, index) => {
        if (value === null || value === "") {
          row.getCell(index + 1).style = dataCellStyle;
        } else {
          row.getCell(index + 1).value = value;
        }

        row.getCell(index + 1).style = dataCellStyle;
      });
    });

    // Auto-size columns to fit content
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const textLength = cell.value ? cell.value.toString()?.length : 0;
        if (textLength > maxLength) {
          maxLength = textLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2; // Minimum width of 10
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${asset === "CPU" ? "Desktop" : asset}'s Data.xlsx`
      );
    });
  };

  return (
    <div>
      {/* <ToastContainer transition={Slide} /> */}
      <button
        className="btn btn-primary flex justify-center items-center min-w-80  cursor-pointer !bg-[#22c55e] !border-[#22c55e]"
        onClick={handleExportClick}
      >
        Export
      </button>
    </div>
  );
}
export default ExcelExportButton;
