import React from "react";
import Modal from "react-bootstrap/Modal";
import "./view-detail.css";
import Tooltip from "@mui/material/Tooltip";

function ShowModal(details) {
  // console.log(details.details);
  let currentAsset = details.details.asset;
  return (
    <Modal
      centered
      className="modal fade show"
      {...details}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Detailed Information: {details.details?.data?.systemId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-3 gap-3 pointer-events-none ">
          <div className="col-span-1">
            <div>
              <span className="font-bold">Serial No: </span>
              {details?.details?.data?.serialNo}
            </div>
          </div>

          <div className="col-span-1">
            <div>
              <span className="font-bold">Product No: </span>
              {details?.details?.data?.productNo}
            </div>
          </div>

          {currentAsset === "Network" || currentAsset === "OtherAsset" ? (
            <div className="col-span-1">
              <div>
                <span className="font-bold">System Model: </span>
                {details?.details?.data?.model}
              </div>
            </div>
          ) : (
            <div className="col-span-1">
              <div>
                <span className="font-bold">System Model: </span>

                {details?.details?.data?.modelNo?.length > 0
                  ? details?.details?.data?.modelNo
                  : "---"}
              </div>
            </div>
          )}

          {currentAsset === "Network" ? (
            <>
              <div className="col-span-1">
                <div>
                  <span className="font-bold">Os: </span>
                  {details?.details?.data?.os}
                </div>
              </div>

              <div className="col-span-1">
                <div>
                  <span className="font-bold">Ram: </span>
                  {details?.details?.data?.ram}
                </div>
              </div>

              <div className="col-span-1">
                <div>
                  <span className="font-bold">Processor: </span>
                  {details?.details?.data?.processor?.length > 0
                    ? details?.details?.data?.processor
                    : "---"}
                </div>
              </div>

              <div className="col-span-1">
                <div>
                  <span className="font-bold">Disk: </span>
                  {details?.details?.data?.diskName}
                </div>
              </div>

              <div className="col-span-1">
                <div>
                  <span className="font-bold">Network Port: </span>
                  {details?.details?.data?.networkPort}
                </div>
              </div>

              {/* <div className="col-span-1">
                <div>
                  <span className="font-bold">Imei: </span>
                  {details?.details?.data?.imei?.length === 0 ||
                  details?.details?.data?.imei === null
                    ? "---"
                    : details?.details?.data?.imei}
                  {console.log(details?.details?.data?.imei)}
                </div>
              </div> */}

              <div className="col-span-1">
                <div>
                  <span className="font-bold">Location: </span>
                  {details?.details?.data?.location}
                </div>
              </div>
            </>
          ) : null}

          <div
            className={`col-span-1 ${
              currentAsset === "Network" || currentAsset === "OtherAsset"
                ? ""
                : "hidden"
            }`}
          >
            <div>
              <span className="font-bold">Asset Type: </span>
              <br />
              {details?.details?.data?.assetType}
            </div>
          </div>

          {currentAsset === "CPU" || currentAsset === "Laptop" ? (
            <>
              <div className="col-span-1">
                <div>
                  <span className="font-bold">Machine Type Model: </span>
                  {details?.details?.data?.macId?.length>0?details?.details?.data?.macId:"---"}
                </div>
              </div>

              {currentAsset === "Laptop"&&
              <div className="col-span-1">
                <div>
                  <span className="font-bold">Dimension: </span>
                  {details?.details?.data?.dimension?.length>0?details?.details?.data?.dimension:"---"}
                </div>
              </div>}

              <div className="col-span-1">
                <div>
                  <span className="font-bold">RAM: </span>
                  {details?.details?.data?.ram}
                </div>
              </div>

              <div className="col-span-1">
                <div>
                  <span className="font-bold">Processor: </span>
                  {details?.details?.data?.processor}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Generation: </span>
                  {details?.details?.data?.generation}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">OS Type: </span>
                  {details?.details?.data?.windowsOS}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Storage Type: </span>
                  {details?.details?.data?.storageType}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Storage Size: </span>
                  {details?.details?.data?.storageSize}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Monitor: </span>
                  {details?.details?.data?.isMonitor ? "Yes" : "No"}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Keyboard: </span>
                  {details?.details?.data?.isKeyborad ? "Yes" : "No"}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Mouse: </span>
                  {details?.details?.data?.isMouse ? "Yes" : "No"}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Headset: </span>
                  {details?.details?.data?.isHeadSet ? "Yes" : "No"}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">AV Installed: </span>
                  {details?.details?.data?.isAVInstalled ? "Yes" : "No"}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">IP Adress: </span>
                  {details?.details?.data?.ipAddress?.length>0?details?.details?.data?.ipAddress.length:"---"}
                </div>
              </div>
            </>
          ) : null}

          <div
            className={`col-span-1 ${
              currentAsset === "Network" || currentAsset === "OtherAsset"
                ? "hidden"
                : ""
            }`}
          >
            <div>
              <span className="font-bold">Brand: </span>
              {details?.details?.data?.brand}
            </div>
          </div>

          <div
            className={`col-span-1 ${
              currentAsset === "Monitor" ? "" : "hidden"
            }`}
          >
            <div>
              <span className="font-bold">Screen Size: </span>
              <br />
              {details?.details?.data?.screensize}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Created By: </span>
              {details?.details?.data?.createdBy}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Purchase Date: </span>
              <br />
              {details?.details?.data?.purchaseDate === null ||
              details?.details?.data?.purchaseDate === undefined
                ? "---"
                : details?.details?.data?.purchaseDate?.split("T")[0]}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Warranty Till: </span>
              <br />
              {details?.details?.data?.warrantyTill === null ||
              details?.details?.data?.warrantyTill === undefined
                ? "---"
                : details?.details?.data?.warrantyTill?.split("T")[0]}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Created On: </span>
              <br />
              {details?.details?.data?.createdOn?.split("T")[0]}
            </div>
          </div>

          <div
            className={`col-span-1 ${
              currentAsset === "OtherAsset" ? "" : "hidden"
            }`}
          >
            <div>
              <span className="font-bold">Imei: </span>
              {details?.details?.data?.imei?.length === 0 ||
              details?.details?.data?.imei === null
                ? "---"
                : details?.details?.data?.imei}
            </div>
          </div>

          <div
            className={`col-span-1 ${
              currentAsset === "OtherAsset" ? "" : "hidden"
            }`}
          >
            <div>
              <span className="font-bold">Wired Type: </span>
              {details?.details?.data?.wiredStatus?.length === 0 ||
              details?.details?.data?.wiredStatus === null
                ? "---"
                : details?.details?.data?.wiredStatus}
            </div>
          </div>

          <div
            className={`col-span-1 ${
              currentAsset === "Network" || currentAsset === "OtherAsset"
                ? "hidden"
                : ""
            }`}
          >
            <div>
              <span className="font-bold">No.of Months: </span>
              <br />
              {details?.details?.data?.noOfMonthsOld}
            </div>
          </div>

          {/* <div
            className={`col-span-1 ${
              currentAsset === "Network" || currentAsset === "OtherAsset"
                ? ""
                : "hidden"
            }`}
          >
            <div>
              <span className="font-bold">Wired Status: </span>
              <br />
              {details?.details?.data?.wiredStatus}
            </div>
          </div> */}

          {currentAsset === "Desktop" || currentAsset === "CPU" ? (
            <>
              <div className=" col-span-1">
                <div>
                  <span className="font-bold">Licensed: </span>
                  {details?.details?.data?.isLicensed ? "Yes" : "No"}
                </div>
              </div>

              <div className=" col-span-1">
                <div>
                  <span className="font-bold">License no: </span>
                  {details?.details?.data?.windowsLicense?.length > 0
                    ? details?.details?.data?.windowsLicense
                    : "---"}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Assigned: </span>
              {details?.details?.data?.isAssigned ? "Yes" : "No"}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Assigned To: </span>
              {details?.details?.data?.assignedTo?.length > 0
                ? details.details.data.assignedTo
                : "---"}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">Assigned To Prev: </span>
              {details?.details?.data?.assignedToPrevious?.length > 0
                ? details.details.data.assignedToPrevious
                : "---"}
            </div>
          </div>

          <div className=" col-span-1">
            <div>
              <span className="font-bold">State: </span>
              {details?.details?.data?.isActive ? "Active" : "In Active"}
            </div>
          </div>

          <div className=" col-span-1">
            <div className="col-span-2">
              <span className="font-bold">Remarks: </span>
              {details?.details?.data?.remarks?.length > 0
                ? details?.details?.data?.remarks
                : "---"}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ViewDetail(data) {
  const [modalShow, setModalShow] = React.useState(false);

  // const [details, setDetails] = useState(props)
  //   const [loading, setLoading] = useState(false)

  // useEffect(()=>{
  //   getDetails();
  // },[])

  return (
    <>
      <Tooltip title="View Detail" placement="top" arrow>
        <div
          className="kr-tbl-view cursor-pointer action-item"
          onClick={() => setModalShow(true)}
        >
          <span className="svg-icon">
            <svg x="0px" y="0px" viewBox="0 0 700 700">
              <g>
                <path
                  d="M671,321.2C635.5,277,507.5,131.6,350,131.6S64.6,277,29.1,321.2c-10,12.7-10,30.8,0,43.5C64.6,409.6,192.6,555,350,555
                                       S635.5,409.6,671,365.4C680.3,352.7,680.3,333.9,671,321.2L671,321.2z M350,482.7c-77,0-139.4-63-139.4-139.4S273,204,350,204
                                       s139.4,63,139.4,139.4C489.4,420.4,427.1,482.7,350,482.7z"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </Tooltip>

      <ShowModal
        details={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ViewDetail;
