import "./App.css";
import { AppSettings } from "./constant";
import AppHeader from "./components/app-header/app-header";
import AppFooter from "./components/app-footer/app-footer";
import RouteContent from "./route";
import LoginPage from "./components/login-page/LoginPage";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "./axios-interceptor";
import { Slide } from "react-toastify";

function App() {
  const [submit, setSubmit] = useState(false);
  const [refresh, SetRefresh] = useState(false);
  const token = localStorage.getItem("accesstoken")
  // window.addEventListener("click", (e) => {
  //   console.log(e.target.className);
  // });

  useEffect(() => {
    // if (submit || token) {
    //   getUsers();
    // }
  }, []);

  // const getUsers = async () => {
  //   try {
  //     const res = await axiosInstance.get(AppSettings.URLs.getAllUsers);

  //     if (res.status === 200) {
  //       SetRefresh(!refresh);
  //     } else {
  //       console.log(`Request failed with status code: ${res.status}`);
  //     }
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // };

  return (
  
    <>
      <ToastContainer transition={Slide} />
      {token || submit ? (
        <>
          <div className={`App`}>
            <AppHeader setSubmit={setSubmit} submit={submit}></AppHeader>
            <RouteContent setSubmit={setSubmit} submit={submit}></RouteContent>

            <AppFooter />
          </div>
        </>
      ) : (
        <LoginPage setSubmit={setSubmit} submit={submit}></LoginPage>
      )}

      {/* {!submit || token.length === 0 ? (
        <LoginPage setSubmit={setSubmit} submit={submit}></LoginPage>
      ) : (
        <div className={`App krFullSreen`}>
          <AppHeader />
          <div id="app__content">
            <RouteContent />
          </div>

          <AppFooter />
        </div>
      )} */}
    </>
  );
}

export default App;
