import React, { useRef, useState } from "react";
import "./app-header.css";
import "../assets/css/main.css";
import mail from "../assets/images/mail.svg";
import alert from "../assets/images/alert.svg";
import user from "../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

// import logo from '../assets/images/Sysfore-logo-dark.svg'

function AppHeader({ submit, setSubmit }) {
  const [logoutPopup, setLogOutPopup] = useState(false);
  const username = localStorage.getItem("username");
  const customId = "custom-id-yes";

  let ref = useRef(null);

  window.addEventListener("click", (e) => {
    if (!ref?.current?.contains(e?.target)) {
      setLogOutPopup(false);
    }
  });

  function toggle() {}

  // let [logoutPopup, setLogOutPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  let logoutUser = () => {
    toast.success("Successfully Logged out", {
      toastId: customId,
    });
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("username");
    localStorage.removeItem("refreshtoken");
    setTimeout(() => {
      setSubmit(!submit);
      navigate("/", { replace: true });
    }, 700);
  };
  return (
    <div id="page_header" className={`${currentPath === "/" ? "hidden" : ""}`}>
      <div className="w-full pl-0 pr-4">
        <div className="kr_header_wrap">
          <div
            className="kr_main_logo text-center cursor-pointer"
            onClick={() => {
              toggle();
            }}
          >
            <svg
              className="sysfore-logo"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 566 129.4"
            >
              <g id="Dark">
                <path
                  className="st0"
                  d="M31.6,98.3c-10.2,0-18.4-1.4-24.7-3.2c-1.2-0.4-2.5-1.1-2.3-2.5l2.2-15.3c0.2-1.6,1.5-2.5,2.7-2.5
		c0.1,0,0.1,0,0.2,0c5.2,0.5,18.4,1.9,25,1.9c6.1,0,8.2-2.4,8.7-6.4c0.4-2.5-0.7-3.9-4.9-6.6L22.1,53.1
		C11.4,46.3,9.4,37.8,10.8,28.2c1.9-13.6,11-24.8,36.1-24.8c9.8,0,22.9,1.6,27.8,3.1c1.1,0.4,2.1,1,1.9,2.4l-2.2,15.6
		c-0.2,1.2-1.2,2.4-2.6,2.4c-0.1,0-0.1,0-0.2,0c-5.1-0.4-19.1-1.6-28-1.6c-4.6,0-6.5,1.6-6.8,4.2c-0.3,2.2,0.6,3.5,5.6,6.4l15.6,9
		c11.2,6.5,14.4,14.7,12.9,25C68.9,83.7,57.3,98.3,31.6,98.3z"
                />
                <path
                  className="st0"
                  d="M166.7,7.2l-24.3,43c-3.5,6.1-6.8,9.3-10.3,10.7l-4.7,33.7c-0.2,1.4-1.5,2.5-2.8,2.5h-20.3
		c-1.4,0-2.2-1.1-2-2.5l4.7-33.7c-3.2-1.4-5.6-4.6-7.3-10.7l-12.3-43c-0.1-0.4-0.2-0.6-0.1-1c0.1-0.9,0.9-1.6,2-1.6h22.5
		c1.2,0,1.8,1.1,2.2,2.4l7.3,33c0.2,1,0.7,1.2,1.1,1.2c0.4,0,0.9-0.2,1.4-1.2l16.6-33c0.7-1.2,1.6-2.4,2.8-2.4h22.5
		c1.1,0,1.6,0.7,1.5,1.6C167,6.6,166.9,6.9,166.7,7.2z"
                />
                <path
                  className="st0"
                  d="M192.1,98.3c-10.2,0-18.4-1.4-24.7-3.2c-1.2-0.4-2.5-1.1-2.3-2.5l2.2-15.3c0.2-1.6,1.5-2.5,2.7-2.5
		c0.1,0,0.1,0,0.2,0c5.2,0.5,18.4,1.9,25,1.9c6.1,0,8.2-2.4,8.7-6.4c0.4-2.5-0.7-3.9-4.9-6.6l-16.4-10.6
		c-10.7-6.8-12.7-15.3-11.3-24.9c1.9-13.6,11-24.8,36.1-24.8c9.8,0,22.9,1.6,27.8,3.1c1.1,0.4,2.1,1,1.9,2.4L235,24.4
		c-0.2,1.2-1.2,2.4-2.6,2.4c-0.1,0-0.1,0-0.2,0c-5.1-0.4-19.1-1.6-28-1.6c-4.6,0-6.5,1.6-6.8,4.2c-0.3,2.2,0.6,3.5,5.6,6.4l15.6,9
		c11.2,6.5,14.4,14.7,12.9,25C229.4,83.7,217.8,98.3,192.1,98.3z"
                />
                <path
                  className="st0"
                  d="M305.8,25.1h-25.2c-4.5,0-6.2,1.4-6.9,6.2l-1.3,9H303c1.4,0,2.3,1.1,2.1,2.5L303,57.5
		c-0.2,1.4-1.5,2.5-2.8,2.5h-30.6l-4.9,34.6c-0.2,1.4-1.5,2.5-2.8,2.5h-20.3c-1.4,0-2.3-1.1-2.1-2.5l8.9-63.3
		c3.4-24,19.9-27.9,35.8-27.9c12.1,0,19.7,0.9,24.3,1.5c1.6,0.2,2.4,1,2.1,2.5l-2.1,15.2C308.4,24,307.1,25.1,305.8,25.1z"
                />
                <path
                  className="st0"
                  d="M341.5,67.9c1,5.6,4.3,8,10.2,8c8.7,0,13.3-5.4,15.2-18.7l1.8-12.8c1.9-13.3-1.2-18.8-9.9-18.8
		c-5.7,0-9.6,2.3-12.2,7.6l-20.8-11.7c8.4-13.1,21.9-18.2,36.1-18.2c20.7,0,36.7,10.7,32.5,41.1l-1.8,12.8c-4.3,30.4-23.3,41-44,41
		c-14.4,0-26.5-5.1-31.2-18.5L341.5,67.9z"
                />
                <path
                  className="st0"
                  d="M474.5,97.2h-22.9c-1.4,0-1.7-1.2-2.1-2.5l-11.4-29.9l-0.8-0.5c-1.2,0-5.2-0.1-6.5-0.1l-4.3,30.5
		c-0.2,1.4-1.5,2.5-2.8,2.5h-20.2c-1.4,0-2.5-1.1-2.3-2.5l11.4-81.4c0.8-6,3-7.3,8.4-8.2c5.1-0.7,17.7-1.6,26.5-1.6
		c23.2,0,37.9,7.3,34.7,30.1l-0.3,2c-1.9,13.3-10.3,21.2-18.6,24.5L476.6,94c0.2,0.5,0.2,1,0.2,1.4
		C476.7,96.4,475.7,97.2,474.5,97.2z M457.2,33.5c0.8-5.9-2.9-8.8-11.2-8.8c-0.9,0-8.9,0.2-9.6,0.2l-2.8,19.8
		c0.6,0.1,8.3,0.1,9.1,0.1c9.6,0,13.4-3.4,14.2-9.2L457.2,33.5z"
                />
                <path
                  className="st0"
                  d="M544.8,96.8c-4.8,0.6-12.7,1.5-24.7,1.5c-15.9,0-31.4-3.7-28-27.9l5.5-39.1c3.4-24,19.9-27.9,35.8-27.9
		c12.1,0,19.7,0.9,24.3,1.5c1.6,0.2,2.4,1,2.1,2.5l-2.1,15.2c-0.2,1.4-1.5,2.5-2.8,2.5h-25.2c-4.5,0-6.2,1.4-6.9,6.2l-1.3,9.1h30.6
		c1.4,0,2.3,1.1,2.1,2.5l-2.1,14.7c-0.2,1.4-1.5,2.5-2.8,2.5h-30.6l-1.5,10.3c-0.7,4.9,0.6,6.2,5.1,6.2h25.2c1.4,0,2.3,1.1,2.1,2.5
		l-2.1,15.2C547.4,95.8,546.4,96.5,544.8,96.8z"
                />
                <path
                  className="st1"
                  d="M323.3,26.5c0,0-3.1,5.1-4.8,17.2l-1.8,14.6c0,0-1.1,9-0.2,15.7l44.1-20.6c2.8-1.3,2.9-5.3,0.2-6.7L323.3,26.5
		z"
                />
                <g>
                  <path
                    className="st2"
                    d="M243,117h-3.7v-1.8h9.5v1.8H245v12.1h-2V117z"
                  />
                  <path
                    className="st2"
                    d="M267.2,115.2h8.4v1.7h-6.4v3.9h5.6v1.8h-5.6v4.5h6.6v1.9h-8.6V115.2z"
                  />
                  <path
                    className="st2"
                    d="M302.7,118.3h-2.1c0-0.5-0.2-0.9-0.5-1.2s-0.7-0.4-1.2-0.4c-0.8,0-1.4,0.5-1.8,1.4s-0.6,2.2-0.6,3.9
			c0,1.8,0.2,3.2,0.6,4.1s1,1.4,1.8,1.4c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1h2.1c-0.1,1.1-0.5,1.9-1.2,2.5
			s-1.5,0.9-2.5,0.9c-1.5,0-2.7-0.6-3.5-1.9c-0.8-1.3-1.2-3.1-1.2-5.5c0-2.2,0.4-3.9,1.3-5.1c0.9-1.2,2-1.9,3.5-1.9
			c1,0,1.9,0.3,2.5,0.9C302.2,116.4,302.6,117.3,302.7,118.3z"
                  />
                  <path
                    className="st2"
                    d="M321.2,115h2v5.9h4.9V115h2v14.1h-2v-6.4h-4.9v6.4h-2V115z"
                  />
                  <path
                    className="st2"
                    d="M348.2,115h2.4l5,10.5V115h1.9v14.1h-2.3l-5.1-10.6v10.6h-1.9V115z"
                  />
                  <path
                    className="st2"
                    d="M385.1,122.1c0,2.1-0.5,3.9-1.4,5.3s-2.2,2-3.6,2s-2.6-0.7-3.6-2c-1-1.4-1.4-3.1-1.4-5.2
			c0-2.1,0.5-3.9,1.4-5.3c1-1.4,2.2-2,3.6-2c1.5,0,2.7,0.7,3.6,2S385.1,119.9,385.1,122.1z M380.1,127.5c0.9,0,1.5-0.5,2.1-1.5
			c0.5-1,0.8-2.3,0.8-4s-0.3-3-0.8-4s-1.2-1.5-2.1-1.5s-1.6,0.5-2.1,1.4s-0.8,2.3-0.8,4s0.3,3,0.8,4
			C378.5,127,379.2,127.5,380.1,127.5z"
                  />
                  <path
                    className="st2"
                    d="M403.5,115h2v12.1h7.1v1.9h-9.1V115z"
                  />
                  <path
                    className="st2"
                    d="M439.5,122.1c0,2.1-0.5,3.9-1.4,5.3c-0.9,1.4-2.2,2-3.6,2s-2.6-0.7-3.6-2c-1-1.4-1.4-3.1-1.4-5.2
			c0-2.1,0.5-3.9,1.4-5.3c1-1.4,2.2-2,3.6-2c1.5,0,2.7,0.7,3.6,2C439,118.2,439.5,119.9,439.5,122.1z M434.5,127.5
			c0.9,0,1.5-0.5,2.1-1.5c0.5-1,0.8-2.3,0.8-4s-0.3-3-0.8-4s-1.2-1.5-2.1-1.5s-1.6,0.5-2.1,1.4c-0.5,1-0.8,2.3-0.8,4s0.3,3,0.8,4
			C432.9,127,433.6,127.5,434.5,127.5z"
                  />
                  <path
                    className="st2"
                    d="M461.4,121.6h5.1v6.9c-0.9,0.3-1.6,0.5-2.3,0.6c-0.6,0.1-1.2,0.2-1.7,0.2c-1.8,0-3.1-0.6-4.1-1.9
			c-1-1.3-1.5-3.1-1.5-5.4c0-2.2,0.5-4,1.5-5.3s2.4-1.9,4.1-1.9c1.1,0,1.9,0.3,2.6,0.8s1.1,1.2,1.3,2.1h-2.1
			c-0.2-0.4-0.5-0.6-0.8-0.8s-0.6-0.3-1-0.3c-1.2,0-2,0.4-2.6,1.4s-0.9,2.2-0.9,4s0.3,3.2,0.9,4.1c0.6,0.9,1.4,1.4,2.6,1.4
			c0.3,0,0.6,0,0.9-0.1s0.7-0.2,1.1-0.3v-3.6h-3v-1.9H461.4z"
                  />
                  <path
                    className="st2"
                    d="M485.4,127.2h2.5V117h-2.1v-1.8h6.2v1.8h-2.1v10.2h2.6v1.9h-7.1V127.2z"
                  />
                  <path
                    className="st2"
                    d="M512.1,115.2h8.4v1.7h-6.4v3.9h5.6v1.8h-5.6v4.5h6.6v1.9h-8.6V115.2z"
                  />
                  <path
                    className="st2"
                    d="M545.5,118.5c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.5-1.5-0.5s-1.1,0.2-1.5,0.5s-0.6,0.8-0.6,1.3
			c0,0.4,0.2,0.8,0.5,1.2s1,0.8,1.9,1.2l0.8,0.4c1.2,0.6,2.1,1.2,2.6,1.9s0.7,1.4,0.7,2.3c0,1.2-0.4,2.1-1.3,2.8c-0.8,0.7-2,1-3.4,1
			c-1.3,0-2.3-0.3-3-1s-1.1-1.7-1.1-3h2.2v0.1c0,0.7,0.2,1.2,0.5,1.6c0.4,0.4,0.9,0.6,1.5,0.6c0.7,0,1.3-0.2,1.8-0.6
			c0.5-0.4,0.7-0.9,0.7-1.5c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.4-1.1-0.8-2.3-1.4l-1-0.5c-0.9-0.4-1.6-1-2.1-1.6s-0.7-1.3-0.7-2.1
			c0-1.1,0.4-2,1.2-2.7s1.9-1,3.2-1c1.2,0,2.2,0.3,2.9,1c0.7,0.6,1.1,1.5,1.2,2.7L545.5,118.5L545.5,118.5z"
                  />
                </g>
              </g>
            </svg>
          </div>

          {/* <div className="kr-all-search ">
            <input
              type="text"
              name="search"
              autocompsete="off"
              placeholder="Search something..."
            />
          </div> */}

          <div className="kr-header-tools !p-0 box-border">
            <ul className="kr-navbar-nav">
              <li className="cursor-pointer !pr-1 ng-star-inserted">
                <img width="18" src={mail} alt="Message" />
              </li>
              <li className="cursor-pointer !px-6 ng-star-inserted">
                <img width="18" alt="alert" src={alert} />
              </li>
              <li className="opacity-30 !pr-2 !pl-1 ng-star-inserted">|</li>

              <li
                ref={ref}
                className="nav-item dropdown !pl-4  !sm:pr:0 !pr-4 "
              >
                <a
                  onClick={() => {
                    setLogOutPopup(!logoutPopup);
                  }}
                  role="button"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="nav-link pt-2 !pl-4 pb-2 !pr-4 sm:pr-0 cursor-pointer"
                >
                  <div className="user-toggle">
                    <div className="user-avatar sm lg:mr-4 mr-4">
                      <img alt="profile" src={user} />
                    </div>
                    <div className="user-info user-info-hide lg:block hidden !mr-0">
                      <div className="user-name dropdown-indicator relative">
                        <div className="user-name-msg">{username}</div>
                        {`${username}@sysfore.com`}
                      </div>
                    </div>
                  </div>
                </a>

                {/* popup-logout */}
                <div
                  className={`absolute inset-y-[0px] dropdown-logout inset-x-auto m-0  dropdown-menu kr-user-ctrl-drop !h-[193.708px] ${
                    logoutPopup ? "show" : ""
                  } `}
                >
                  {/* first-list */}
                  <div className="dropdown-inner user-card-wrap bg-[#fc5b0b] rounded-tr-[8px] rounded-tl-[8px] ">
                    <div className=" user-card flex items-start">
                      <div className="user-avatar bg-white">
                        <img alt="profile" src={user} />
                      </div>
                      <div className=" user-info ml-[1rem]">
                        <span className="lead-text text-white !text-[11px]">
                          {username}
                        </span>
                        <span className=" sub-text text-white !text-[10px] text-break">{`${username}@sysfore.com`}</span>
                      </div>
                    </div>
                  </div>
                  {/* first-list */}

                  {/* second-list */}
                  {/* <div className="dropdown-inner bg-white">
                    <ul className="link-list">
                      <li>
                        <a className=" cursor-pointer">
                          <span className="">
                            <svg
                              _ngcontent-jrg-c154=""
                              height="15"
                              width="15"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 19.738 19.738"
                            >
                              <g _ngcontent-jrg-c154="">
                                <path
                                  _ngcontent-jrg-c154=""
                                  d="M18.18,19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31,2.745-6.31,6.118h-2
		c0-4.478,3.729-8.118,8.311-8.118C14.451,11.62,18.18,15.26,18.18,19.738z"
                                ></path>
                                <path
                                  _ngcontent-jrg-c154=""
                                  d="M9.87,10.97c-3.023,0-5.484-2.462-5.484-5.485C4.385,2.461,6.846,0,9.87,0
		c3.025,0,5.486,2.46,5.486,5.485S12.895,10.97,9.87,10.97z M9.87,2C7.948,2,6.385,3.563,6.385,5.485S7.948,8.97,9.87,8.97
		c1.923,0,3.486-1.563,3.486-3.485S11.791,2,9.87,2z"
                                ></path>
                              </g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                            </svg>
                          </span>
                          <span className=" pl-[0.5rem]">View Profile</span>
                        </a>
                      </li>
                      <li>
                        <a className=" cursor-pointer">
                          <span>
                            <svg
                              _ngcontent-jrg-c154=""
                              height="15"
                              width="15"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g _ngcontent-jrg-c154="">
                                <g _ngcontent-jrg-c154="">
                                  <path
                                    _ngcontent-jrg-c154=""
                                    d="M256,176c-44.113,0-80,35.888-80,80s35.888,80,80,80s80-35.888,80-80S300.113,176,256,176z M256,296
			c-22.056,0-40-17.944-40-40c0-22.056,17.944-40,40-40c22.056,0,40,17.944,40,40C296,278.056,278.056,296,256,296z"
                                  ></path>
                                </g>
                              </g>
                              <g _ngcontent-jrg-c154="">
                                <g _ngcontent-jrg-c154="">
                                  <path
                                    _ngcontent-jrg-c154=""
                                    d="M502.164,315.637l-51.788-39.683c1.456-13.725,1.367-27.02,0-39.907l51.788-39.683c8.029-6.152,10.201-17.33,5.059-26.041
			l-50.152-84.978c-4.996-8.466-15.405-12.046-24.553-8.443l-61.445,24.2c-11.222-8.031-23.233-14.836-35.904-20.341l-9.224-63.63
			C324.519,7.296,316.089,0,306.152,0H205.848c-9.937,0-18.367,7.296-19.792,17.132l-9.224,63.63
			c-12.671,5.506-24.682,12.31-35.904,20.341l-61.445-24.2c-9.15-3.604-19.557-0.023-24.553,8.443L4.777,170.323
			c-5.141,8.711-2.969,19.889,5.059,26.041l51.789,39.682c-1.456,13.725-1.367,27.02,0,39.907L9.837,315.636
			c-8.029,6.152-10.201,17.33-5.059,26.041l50.151,84.978c4.996,8.466,15.404,12.046,24.553,8.443l61.446-24.199
			c11.22,8.029,23.232,14.835,35.903,20.34l9.224,63.63c1.426,9.835,9.856,17.131,19.793,17.131h100.304
			c9.937,0,18.367-7.296,19.793-17.13l9.224-63.63c12.671-5.505,24.683-12.31,35.903-20.34l61.446,24.199
			c9.149,3.603,19.557,0.022,24.553-8.443l50.152-84.978C512.364,332.967,510.192,321.789,502.164,315.637z M431.297,391.626
			l-56.047-22.073c-6.716-2.643-14.328-1.467-19.93,3.077c-13.523,10.973-28.702,19.557-45.114,25.515
			c-6.928,2.515-11.91,8.636-12.968,15.93L288.842,472h-65.685l-8.395-57.925c-1.058-7.294-6.04-13.415-12.968-15.93
			c-16.412-5.959-31.591-14.543-45.114-25.515c-5.602-4.544-13.218-5.72-19.93-3.077l-56.047,22.073l-32.471-55.019l46.96-35.984
			c5.902-4.523,8.809-11.946,7.545-19.274c-2.944-17.075-2.847-34.187,0-50.699c1.264-7.328-1.643-14.752-7.545-19.274
			l-46.96-35.984l32.471-55.019l56.047,22.073c6.715,2.645,14.328,1.467,19.93-3.077c13.524-10.972,28.703-19.556,45.114-25.514
			c6.928-2.515,11.91-8.636,12.968-15.93L223.158,40h65.685l8.395,57.925c1.058,7.294,6.04,13.415,12.968,15.93
			c16.411,5.958,31.59,14.542,45.114,25.514c5.602,4.545,13.217,5.721,19.93,3.077l56.047-22.073l32.47,55.019l-46.96,35.984
			c-5.902,4.523-8.809,11.946-7.545,19.274c2.944,17.075,2.847,34.187,0,50.699c-1.264,7.328,1.643,14.752,7.545,19.274
			l46.96,35.984L431.297,391.626z"
                                  ></path>
                                </g>
                              </g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                              <g _ngcontent-jrg-c154=""></g>
                            </svg>
                          </span>
                          <span className=" pl-[0.5rem]">Account Settings</span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {/* second-list */}

                  {/* third-list */}
                  <div
                    className="dropdown-inner bg-white rounded-br-[8px] rounded-bl-[8px] !border-t-[#e2e2e2] !border-[none] !border-t !border-solid;
} "
                  >
                    <ul className="link-list">
                      <li onClick={logoutUser}>
                        <a className=" cursor-pointer">
                          <span>
                            <svg
                              _ngcontent-wga-c154=""
                              height="15"
                              viewBox="0 0 512 512"
                              width="15"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g _ngcontent-wga-c154="" id="Solid">
                                <path
                                  _ngcontent-wga-c154=""
                                  d="m480.971 239.029-90.51-90.509a24 24 0 0 0 -33.942 0 24 24 0 0 0 0 33.941l49.54 49.539h-262.059a24 24 0 0 0 -24 24 24 24 0 0 0 24 24h262.059l-49.54 49.539a24 24 0 0 0 33.942 33.941l90.51-90.51a24 24 0 0 0 0-33.941z"
                                ></path>
                                <path
                                  _ngcontent-wga-c154=""
                                  d="m304 392a24 24 0 0 0 -24 24v24h-208v-368h208v24a24 24 0 0 0 48 0v-32a40 40 0 0 0 -40-40h-224a40 40 0 0 0 -40 40v384a40 40 0 0 0 40 40h224a40 40 0 0 0 40-40v-32a24 24 0 0 0 -24-24z"
                                ></path>
                              </g>
                            </svg>
                          </span>
                          <span className=" pl-[0.5rem]">Sign out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* third-list */}
                </div>
                {/* popup-logout */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <ToastContainer transition={Slide} /> */}
    </div>
  );
}

export default AppHeader;
