import axios from "axios";
import { AppSettings } from "./constant";

// Create an instance of axios
const axiosInstance = axios.create();
let loginData = null;
export function setLoginData(data) {
  loginData = data;
}

//  included the bearer token in all requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accesstoken");

    // If a token exists, set the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log(error);
    console.log(error.message);
    console.log(error.response);
   
    if (error?.response?.status === 403) {
      if(!localStorage.getItem("refreshtoken")){
        localStorage.clear();
        window.location.href="/"
      }
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve) => {
            refreshSubscribers.push((newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(newToken);
            });
          });
          return axiosInstance(originalRequest);
        } catch (error) {
          console.error("Token refresh failed:", error);
          // Redirect to login page

          throw error;
        } finally {
          isRefreshing = false;
          refreshSubscribers = [];
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        let refreshtoken = localStorage.getItem("refreshtoken");
        let username = localStorage.getItem("username");
        const response = await axios.post(AppSettings.URLs.login, {
          username: username,
          granttype: "refresh",
          refreshtoken: refreshtoken,
        });

        const newAccessToken = response.data.response.token.accesstoken;
        localStorage.setItem("accesstoken", newAccessToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        refreshSubscribers.forEach((subscriber) => subscriber(newAccessToken));
        return axiosInstance(originalRequest);
      } catch (error) {
        // redirect
        localStorage.clear()
      window.location.href="/"
      throw error;
      } finally {
        isRefreshing = false;
        refreshSubscribers = [];
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
