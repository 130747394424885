import React from "react";
import "../assets/css/main.css";
import asset from "../assets/images/computer.png";
import assign from "../assets/images/worker.png";
import user from "../assets/images/assign.png";
import report from "../assets/images/report.png";
// import system from '../assets/images/system.png'
import { Link } from "react-router-dom";
import AppHeader from "../app-header/app-header";

function Master() {
  return (
    <>
      <div className="page-titles bg-white px-6 ">
        <div className="flex items-center">
          <div>
            <h2 className="font-bold color-dark text-[14px] mb-0">
              Inventory Manangement
            </h2>
            <span className="text-gray-400 text-[12px]">
              Manage all your inventories here
            </span>
          </div>
        </div>
      </div>

      <div className="pageBody-inner p-6">
        <div className="kr-block">
          <div className="kr-files-group">
            <div className="kr-files-list">
              {/* <Link to='/system-management' className='kr-file-item kr-file-6 p-4 cursor-pointer kr-hover-zoom kr-master-widget-orange kr-invent-orange'>
                    <div className='w-100 kr-master-widget relative lg:block md:block sm:block flex gap-2 items-center'>
                        <div className='kr-block kr-master-widget-icon'>
                            <img className='mx-auto' width='85%' src={system} alt='Building Master'/>
                        </div>
                        <div className='kr-block kr-master-widget-text mt-4'>
                            <h2 className='font-bold text-[#061237] text-[14px] mb-0 uppercase'>System management</h2>
                            <span className='text-[#666c78] text-[12px]'>View or Add Assets here</span>
                        </div>
                        <div className='kr-master-arrow kr-orange-arrow'>
                        <svg className='svg-white' version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 100 99">
                            <g>
	                            <path d="M95.9,45.2L65.4,14.7c-2.1-2.1-5.5-2.1-7.5,0c-2.1,2.1-2.1,5.5,0,7.5l21.5,21.5H7.8c-2.9,0-5.3,2.4-5.3,5.3
		                                c0,2.9,2.4,5.3,5.3,5.3h71.5L57.9,75.8c-2.1,2.1-2.1,5.5,0,7.5c1,1,2.4,1.6,3.8,1.6s2.7-0.5,3.8-1.6l30.6-30.6c1-1,1.6-2.4,1.6-3.8
		                                C97.5,47.6,96.9,46.2,95.9,45.2z"/>
                            </g>
                        </svg>
                    </div>
                    </div>
                    
                </Link> */}

              <Link
                to="/user-management"
                className="kr-file-item kr-file-6 p-4 cursor-pointer kr-hover-zoom kr-master-widget-purple kr-invent-purple"
              >
                <div className="w-100 kr-master-widget relative lg:block md:block sm:block flex gap-2 items-center">
                  <div className="kr-block kr-master-widget-icon">
                    <img
                      className="mx-auto"
                      width="85%"
                      src={user}
                      alt="Building Master"
                    />
                  </div>
                  <div className="kr-block kr-master-widget-text mt-4">
                    <h2 className="font-bold text-[#061237] text-[14px] mb-0 uppercase">
                      user Management
                    </h2>
                    <span className="text-[#666c78] text-[12px]">
                      View or add Users here
                    </span>
                  </div>
                  <div className="kr-master-arrow kr-purple-arrow">
                    <svg
                      className="svg-white"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 99"
                    >
                      <g>
                        <path
                          d="M95.9,45.2L65.4,14.7c-2.1-2.1-5.5-2.1-7.5,0c-2.1,2.1-2.1,5.5,0,7.5l21.5,21.5H7.8c-2.9,0-5.3,2.4-5.3,5.3
		                                c0,2.9,2.4,5.3,5.3,5.3h71.5L57.9,75.8c-2.1,2.1-2.1,5.5,0,7.5c1,1,2.4,1.6,3.8,1.6s2.7-0.5,3.8-1.6l30.6-30.6c1-1,1.6-2.4,1.6-3.8
		                                C97.5,47.6,96.9,46.2,95.9,45.2z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
              <Link
                to="/asset-management"
                className="kr-file-item kr-file-6 p-4 cursor-pointer kr-hover-zoom kr-master-widget-blue kr-invent-blue"
              >
                <div className="w-100 kr-master-widget relative lg:block md:block sm:block flex gap-2 items-center">
                  <div className="kr-block kr-master-widget-icon">
                    <img
                      className="mx-auto"
                      width="85%"
                      src={asset}
                      alt="Building Master"
                    />
                  </div>
                  <div className="kr-block kr-master-widget-text mt-4">
                    <h2 className="font-bold text-[#061237] text-[14px] mb-0 uppercase">
                      Asset management
                    </h2>
                    <span className="text-[#666c78] text-[12px]">
                      View or Add Assets here
                    </span>
                  </div>
                  <div className="kr-master-arrow kr-blue-arrow">
                    <svg
                      className="svg-white"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 99"
                    >
                      <g>
                        <path
                          d="M95.9,45.2L65.4,14.7c-2.1-2.1-5.5-2.1-7.5,0c-2.1,2.1-2.1,5.5,0,7.5l21.5,21.5H7.8c-2.9,0-5.3,2.4-5.3,5.3
		                                c0,2.9,2.4,5.3,5.3,5.3h71.5L57.9,75.8c-2.1,2.1-2.1,5.5,0,7.5c1,1,2.4,1.6,3.8,1.6s2.7-0.5,3.8-1.6l30.6-30.6c1-1,1.6-2.4,1.6-3.8
		                                C97.5,47.6,96.9,46.2,95.9,45.2z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
              {/* <Link to='/workstation-assignment' className='kr-file-item kr-file-6 p-4 cursor-pointer kr-hover-zoom kr-master-widget-green kr-invent-green'>
                    <div className='w-100 kr-master-widget relative lg:block md:block sm:block flex gap-2 items-center'>
                        <div className='kr-block kr-master-widget-icon'>
                            <img className='mx-auto' width='85%' src={assign} alt='Building Master'/>
                        </div>
                        <div className='kr-block kr-master-widget-text mt-4'>
                            <h2 className='font-bold text-[#061237] text-[14px] mb-0 uppercase'>Asset Assignment</h2>
                            <span className='text-[#666c78] text-[12px]'>Assign asset to users here</span>
                        </div>
                        <div className='kr-master-arrow kr-green-arrow'>
                        <svg className='svg-white' version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 100 99">
                            <g>
	                            <path d="M95.9,45.2L65.4,14.7c-2.1-2.1-5.5-2.1-7.5,0c-2.1,2.1-2.1,5.5,0,7.5l21.5,21.5H7.8c-2.9,0-5.3,2.4-5.3,5.3
		                                c0,2.9,2.4,5.3,5.3,5.3h71.5L57.9,75.8c-2.1,2.1-2.1,5.5,0,7.5c1,1,2.4,1.6,3.8,1.6s2.7-0.5,3.8-1.6l30.6-30.6c1-1,1.6-2.4,1.6-3.8
		                                C97.5,47.6,96.9,46.2,95.9,45.2z"/>
                            </g>
                        </svg>
                    </div>
                    </div>
                    
                </Link> */}
              {/* <Link to='/report' className='kr-file-item kr-file-6 p-4 cursor-pointer kr-hover-zoom kr-master-widget-pink kr-invent-pink'>
                    <div className='w-100 kr-master-widget relative lg:block md:block sm:block flex gap-2 items-center'>
                        <div className='kr-block kr-master-widget-icon'>
                            <img className='mx-auto' width='80%' src={report} alt='Building Master'/>
                        </div>
                        <div className='kr-block kr-master-widget-text mt-4'>
                            <h2 className='font-bold text-[#061237] text-[14px] mb-0 uppercase'>Reports</h2>
                            <span className='text-[#666c78] text-[12px]'>View reports here</span>
                        </div>
                        <div className='kr-master-arrow kr-pink-arrow'>
                        <svg className='svg-white' version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 100 99">
                            <g>
	                            <path d="M95.9,45.2L65.4,14.7c-2.1-2.1-5.5-2.1-7.5,0c-2.1,2.1-2.1,5.5,0,7.5l21.5,21.5H7.8c-2.9,0-5.3,2.4-5.3,5.3
		                                c0,2.9,2.4,5.3,5.3,5.3h71.5L57.9,75.8c-2.1,2.1-2.1,5.5,0,7.5c1,1,2.4,1.6,3.8,1.6s2.7-0.5,3.8-1.6l30.6-30.6c1-1,1.6-2.4,1.6-3.8
		                                C97.5,47.6,96.9,46.2,95.9,45.2z"/>
                            </g>
                        </svg>
                    </div>
                    </div>
                    
                </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Master;
