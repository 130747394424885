import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { AppSettings } from "../../constant";
import axiosInstance from "../../axios-interceptor";
import axios from "axios";
function Report() {
  const [currentAsset, setCurrentAsset] = useState("CPU");
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDropDownvalue, setSelectedDropDownValue] = useState({});

  const handleAssetClick = (event) => {
    setCurrentAsset(event);
  };

  const fetchDropdownOptions = async () => {
    try {
      const data = await axiosInstance.get(
        AppSettings.URLs.asset +
          currentAsset +
          "/GetAllActive" +
          currentAsset +
          "sAsync"
      );

      if (currentAsset === "CPU") {
        setDropdownOptions(data?.data?.response?.cpus);
      } else {
        setDropdownOptions(data?.data?.response?.laptops);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let handleChange = (event) => {
    setSelectedDropDownValue({
      value: event,
      label: event,
    });
  };

  useEffect(() => {
    fetchDropdownOptions();

    setDropdownOptions([]);
  }, [currentAsset]);

  //Pagination
  const itemsPerPage = 10;
  const totalPages = data ? Math.ceil(data?.length / itemsPerPage) : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const displayedData = data ? data.slice(startIndex, endIndex) : [];
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const goToNextPage = () => {
    if (endIndex < data?.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const renderPageNumbers = () => {
    const maxPageNumbersToShow = 3; // Adjust this value as per your preference
    const pageNumbers = [];
    let startPage;
    let endPage;

    if (totalPages <= maxPageNumbersToShow) {
      // Show all page numbers if the total pages are less than or equal to the maxPageNumbersToShow
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate the start and end page numbers with an ellipsis
      const middlePage = Math.ceil(maxPageNumbersToShow / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = maxPageNumbersToShow;
      } else if (currentPage + middlePage - 1 >= totalPages) {
        startPage = totalPages - maxPageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage + 1;
        endPage = currentPage + middlePage - 1;
      }
    }

    // Add ellipsis at the beginning if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Add page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis at the end if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((pageNumber) =>
      typeof pageNumber === "number" ? (
        <li
          key={pageNumber}
          className={`inline-block cursor-pointer hover:backdrop-brightness-90 ${
            currentPage === pageNumber ? "current" : ""
          }`}
          onClick={() => setCurrentPage(pageNumber)}
        >
          <span>{pageNumber}</span>
        </li>
      ) : (
        <li key={pageNumber} className="inline-block mx-1">
          {pageNumber}
        </li>
      )
    );
  };

  return (
    <>
      <div className="page-titles bg-white px-6 ">
        <div className="flex items-center">
          <div className="mr-4 kr-page-back-btn">
            <NavLink to="/master" className="no-underline">
              <button
                type="button"
                className="btn-primary rounded flex items-center justify-center w-8"
              >
                <svg
                  width={18}
                  className="svg-white"
                  x="0px"
                  y="0px"
                  viewBox="0 0 700 700"
                >
                  <g>
                    <path
                      d="M82.9,331.2l148.3,148.3c7.6,7.6,18.6,10.6,29,7.8c10.4-2.8,18.5-10.9,21.2-21.2c2.8-10.4-0.2-21.4-7.8-29l-97.6-96.9
      h304.7c15.8,0,31,6.3,42.1,17.5c11.2,11.2,17.5,26.3,17.5,42.1v79c0,10.6,5.7,20.5,14.9,25.8c9.2,5.3,20.6,5.3,29.8,0
      c9.2-5.3,14.9-15.2,14.9-25.8v-79c0-31.6-12.6-61.9-34.9-84.3c-22.4-22.4-52.7-34.9-84.3-34.9H176.1l97.6-97.6
      c7.6-7.6,10.6-18.6,7.8-29c-2.8-10.4-10.9-18.5-21.2-21.2c-10.4-2.8-21.4,0.2-29,7.8L82.9,288.7c-5.7,5.6-8.9,13.3-8.9,21.2
      S77.2,325.6,82.9,331.2L82.9,331.2z"
                    ></path>
                  </g>
                </svg>
              </button>
            </NavLink>
          </div>
          <div>
            <h2 className="font-bold color-dark text-[14px] mb-0">History</h2>
            <span className="text-gray-400 text-[12px]">
              View all History used the Assets here
            </span>
          </div>
        </div>
      </div>

      <div className="pageBody-inner px-0 pt-0">
        <div className="kr-block">
          <div className="tabs">
            <div className="tab-button-outer">
              <div id="tab-button">
                <li className={`${currentAsset === "CPU" ? "is-active" : ""}`}>
                  <div
                    className="kr-asset-tab"
                    onClick={() => {
                      handleAssetClick("CPU");
                    }}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "CPU"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      CPU
                    </div>
                  </div>
                </li>

                <li
                  className={`${currentAsset === "Laptop" ? "is-active" : ""}`}
                >
                  <div
                    className="kr-asset-tab"
                    onClick={() => {
                      handleAssetClick("Laptop");
                    }}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "Laptop"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      LAPTOP
                    </div>
                  </div>
                </li>
              </div>
            </div>
            <div className="pageBody-inner p-6">
              <div className="kr-block">
                <div className="card !border-none">
                  <div className="card-inner p-0">
                    <div className="kr-table-header border-bottom px-6 py-3">
                      <div className="w-100 flex items-center mb-lg-0 -md-0 gap-[1rem]">
                        <div className="kr-table-filter">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="flex items-center p-2 px-3 btn btn-light border border-solid  border-gray-200 btn"
                              aria-expanded="false"
                            >
                              <svg
                                width="12"
                                x="0px"
                                y="0px"
                                viewBox="0 0 700 700"
                              >
                                <g>
                                  <path
                                    d="M618.3,89.7H81.7c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.8,22.4,38.7,22.4h536.6
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C649,98.2,634.3,89.7,618.3,89.7z"
                                  ></path>
                                  <path
                                    d="M528.9,313.3H171.1c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.8,22.4,38.7,22.4h357.7
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C559.6,321.8,544.8,313.3,528.9,313.3L528.9,313.3z"
                                  ></path>
                                  <path
                                    d="M439.4,536.9H260.6c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.7,22.4,38.7,22.4h178.9
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C470.2,545.4,455.4,536.9,439.4,536.9z"
                                  ></path>
                                </g>
                              </svg>
                              <span className="pl-2">Filter</span>
                            </button>
                            <div></div>
                          </div>
                        </div>

                        <div className="kr-table-filter">
                          <div className="dropdown">
                            <Select
                              className="placeholder-[#212529]"
                              searchable={false}
                              backspaceDelete={false}
                              placeholder="Asset Id"
                              name="Asset Id"
                              options={dropdownOptions?.map((option) => ({
                                value: option.systemId,
                                label: option.systemId,
                              }))}
                              onChange={(selectedValue) => {
                                handleChange(selectedValue);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="kr-block px-3 pt-2 pb-3">
                      <div className="overflow-x-auto">
                        {loading ? (
                          <table className="table kr-table-striped">
                            <thead>
                              <tr>
                                <th rowSpan="2" className="text-center">
                                  #
                                </th>
                                <th rowSpan="2">System ID</th>
                                <th rowSpan="2">S.no(on box)</th>
                                <th rowSpan="2">Brand</th>
                                <th rowSpan="2">System ID</th>
                                <th rowSpan="2">S.no(on box)</th>
                                <th rowSpan="2">Brand</th>
                                <th rowSpan="2">System ID</th>
                                <th rowSpan="2">S.no(on box)</th>
                                <th rowSpan="2">Brand</th>
                                <th rowSpan="2">System ID</th>
                                <th rowSpan="2">S.no(on box)</th>
                                <th rowSpan="2">Brand</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        ) : (
                          <div className="w-100 flex justify-center items-center py-6 bg-light">
                            <div className="kr-pageLoader">
                              <span className="loader"></span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="kr-block pt-3 pb-3 font-size-12px color-gray border-top px-3">
                      <nav className="w-full position:relative m-0">
                        <ul className="list-none ml-0 mb-0 pagination">
                          <li
                            className={`inline-block pagination-prev cursor-pointer ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            onClick={goToPreviousPage}
                          >
                            <span>Previous</span>
                          </li>
                          {renderPageNumbers()}
                          <li
                            className={`inline-block pagination-next cursor-pointer ${
                              endIndex >= data?.length ? "disabled" : ""
                            }`}
                            onClick={goToNextPage}
                          >
                            <span>Next</span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
