import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../view-detail/view-detail.css";
import Tooltip from "@mui/material/Tooltip";
import ATImage from "../assets/images/AT.png";
import DTImage from "../assets/images/DT.png";
import LTImage from "../assets/images/LT.png";
import MTImage from "../assets/images/MT.png";
import NTImage from "../assets/images/NT.png";

function ShowModal(details) {
  const userData = details.details.data;
  const usersName = details?.details?.data.username;
  const assignedAsset = userData?.assignedasset
    .split(",")
    .filter((item) => item.trim() !== "");
  const hasAssignedAssets = assignedAsset.length > 0;
  return (
    <Modal
      centered
      className="modal fade show"
      {...details}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className=" !text-lg uppercase"
        >
          Asset Assigned
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="">
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {hasAssignedAssets ? (
              // Render assets if there are any
              assignedAsset.map((value, index) => {
                // Use the switch statement to determine the imageSrc
                let imageName = value.split("F")[1].substring(0, 2);
                let imageSrc;

                switch (imageName) {
                  case "AT":
                    imageSrc = ATImage;
                    break;
                  case "DT":
                    imageSrc = DTImage;
                    break;
                  case "LT":
                    imageSrc = LTImage;
                    break;
                  case "MT":
                    imageSrc = MTImage;
                    break;
                  case "NT":
                    imageSrc = NTImage;
                    break;
                  default:
                    break;
                }

                return (
                  <li
                    className="bg-[#c6f8e9] h-[38px] w-auto flex  justify-center items-center gap-x-4  rounded-md hover:scale-[1.1] transition-all duration-300 text-center "
                    key={index}
                  >
                    <img
                      className=" h-[22px] w-[22px] hidden md:block "
                      src={imageSrc}
                      alt={value}
                    />
                    <span>{`${index + 1}. ${value}`}</span>
                  </li>
                );
              })
            ) : (
              // Render the "no asset" message when there are no assets
              <li
                className="bg-[#c6f8e9] h-[38px] w-auto flex justify-center items-center rounded-md hover:scale-[1.1] transition-all duration-300 text-center whitespace-nowrap "
                key={0}
              >
                <span className="font-medium capitalize mr-[2px]">
                  {usersName}
                </span>{" "}
                not having any assigned assets
              </li>
            )}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ViewAsset(data) {
  const [modalShow, setModalShow] = React.useState(false);

  // const [details, setDetails] = useState(props)
  //   const [loading, setLoading] = useState(false)

  // useEffect(()=>{
  //   getDetails();
  // },[])

  return (
    <>
      <Tooltip title="Asset Detail" placement="top" arrow>
        <div
          className="kr-tbl-view cursor-pointer action-item"
          onClick={() => setModalShow(true)}
        >
          <span className="svg-icon">
            <svg x="0px" y="0px" viewBox="0 0 700 700">
              <g>
                <path
                  d="M671,321.2C635.5,277,507.5,131.6,350,131.6S64.6,277,29.1,321.2c-10,12.7-10,30.8,0,43.5C64.6,409.6,192.6,555,350,555
                                       S635.5,409.6,671,365.4C680.3,352.7,680.3,333.9,671,321.2L671,321.2z M350,482.7c-77,0-139.4-63-139.4-139.4S273,204,350,204
                                       s139.4,63,139.4,139.4C489.4,420.4,427.1,482.7,350,482.7z"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </Tooltip>

      <ShowModal
        details={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ViewAsset;
