import React, { useState, useEffect } from "react";

import { AppSettings } from "../../constant";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import Select from "react-dropdown-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Tooltip from "@mui/material/Tooltip";
import ViewAsset from "./viewAsset";
import axiosInstance from "../../axios-interceptor";

function UserManagement() {
  const [data, setData] = useState([]);
  const customId = "custom-id-yes";
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarAction, setSidebarAction] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [dataError, setDataError] = useState({
    empid: false,
    username: false,
    designation: false,
    department: false,
    email: false,
  });
  const [selectedValue, setSelectedValue] = useState({
    empid: { value: "", label: "" },
    username: { value: null, label: "" },
    designation: { value: null, label: "" },
    department: { value: null, label: "Department" },
    email: { value: null, label: "" },
  });

  const [searchFilter, setSearchFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  //To get all Users
  const getUsers = async () => {
    try {
      const res = await axiosInstance.get(AppSettings.URLs.getAllUsers);
      if (res != null) {
        setData(res?.data?.response?.users);
        setFilteredData(res?.data?.response?.users);
        setLoading(true);
        setSearchFilter("")
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getUsers(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To handle the input form the user
  const handleInputChange = (e, name) => {
    let value;
    if (e && e.target) {
      // Regular input field
      value = e?.target?.value;
      setSelectedValue((option) => ({
        ...option,
        [name]: { value: null, label: value },
      }));
    } else {
      // React Select component
      value = e ? e[0]?.value : "";
      setSelectedValue((option) => ({
        ...option,
        [name]: { value: e ? e[0]?.value : "", label: e ? e[0]?.label : "" },
      }));
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  //To Open sideBar
  const handleSidebarOpen = (action, item) => {
    setIsSidebarOpen(true);
    setSidebarAction(action);
    setInputValues(item);
    setEditItemId(item?.id);
    if (item !== null) {
      setSelectedValue({
        empid: { value: item?.empid, label: item?.empid },
        username: { value: item?.username, label: item?.username },
        designation: { value: item?.designation, label: item?.designation },
        department: { value: item?.department, label: item?.department },
        email: { value: item?.email, label: item?.email },
      });
    } else {
      setSelectedValue({
        empid: { value: "", label: "" },
        username: { value: null, label: "" },
        designation: { value: null, label: "" },
        department: { value: null, label: "Department" },
        email: { value: null, label: "" },
      });
    }
  };

  //To Close SideBar
  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
    setSidebarAction("");
    setEditItemId(null);
    setInputValues({});
    setSelectedValue({
      empid: { value: "", label: "" },
      username: { value: null, label: "" },
      designation: { value: null, label: "" },
      department: { value: null, label: "Department" },
      email: { value: null, label: "" },
    });
    setDataError({
      empid: false,
      username: false,
      designation: false,
      department: false,
      email: false,
    });
  };

  //To handle submit action of Sidebar
  const handleSidebarFormSubmit = (event) => {
    let isFormFilled = false;
    setDataError({
      empid:
        inputValues?.empid === undefined || inputValues?.empid === ""
          ? true
          : false,
      username:
        inputValues?.username === undefined || inputValues?.username === ""
          ? true
          : false,
      designation:
        inputValues?.designation === undefined ||
        inputValues?.designation === ""
          ? true
          : false,
      department:
        inputValues?.department === undefined || inputValues?.department === ""
          ? true
          : false,
      email:
        inputValues?.email === undefined || inputValues?.email === ""
          ? true
          : false,
    });

    if (
      inputValues?.empid !== undefined &&
      inputValues?.empid !== "" &&
      inputValues?.username !== undefined &&
      inputValues?.username !== "" &&
      inputValues?.designation !== undefined &&
      inputValues?.designation !== "" &&
      inputValues?.department !== undefined &&
      inputValues?.department !== "" &&
      inputValues?.email !== undefined &&
      inputValues?.email !== ""
    ) {
      isFormFilled = true;
    }

    if (isFormFilled === true) {
      if (sidebarAction === "add") {
        const selectedUser = data?.find(
          (item) => item.empid === inputValues.empid
        );
        if (
          inputValues.empid === selectedUser?.empid ||
          !inputValues?.empid?.toLowerCase().startsWith("sys-") ||
          inputValues?.empid === "sys-"
        ) {
          setDataError((prev) => ({
            ...prev,
            empid: true,
          }));
          if (inputValues.empid.toLowerCase() === selectedUser?.empid.toLowerCase()) {
            toast.error("Employee ID already exists", {
              toastId: customId,
            });
          } else {
            toast.error("Please enter proper Employee id", {
              toastId: customId,
            });
          }
        }

        if (/\d/.test(inputValues?.username)) {
          setDataError((prev) => ({
            ...prev,
            username: true,
          }));
          toast.error("Employee name can't contains no", {
            toastId: customId,
          });
        }

        if (!inputValues?.email?.includes("@sysfore")) {
          setDataError((prev) => ({
            ...prev,
            email: true,
          }));

          toast.error("Email Id is not valid", {
            toastId: customId,
          });
        }
        if (
          inputValues.empid !== selectedUser?.empid &&
          inputValues?.empid?.toLowerCase().startsWith("sys-") &&
          inputValues?.empid !== "sys-" &&
          !/\d/.test(inputValues?.username) &&
          inputValues?.email?.includes("@sysfore")
        ) {
          try {
            axiosInstance
              .post(AppSettings.URLs.addUser, inputValues)
              .then(() => {
                getUsers();
                toast.success("Submitted Successfully", {
                  toastId: customId,
                });
              })
              .catch((error) => {
                console.log(error);
                toast.error("Something went wrong", {
                  toastId: customId,
                });
              });
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong", {
              toastId: customId,
            });
          }
          handleSidebarClose();
          setInputValues({});
        }
      } else if (sidebarAction === "edit") {
        try {
          axiosInstance
            .put(AppSettings.URLs.updateUser + "?id=" + editItemId, inputValues)
            .then(() => {
              getUsers();
              toast.success("Updated Successfully", {
                toastId: customId,
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
        handleSidebarClose();
        setInputValues({});
      }
    } else {
      toast.error("Please fill all the required fields", {
        toastId: customId,
      });
    }
  };

  const deleteUser = (data) => {
    confirmAlert({
      title: "Delete User",
      message: `Are you sure delete this user: ${data.username}.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              axiosInstance
                .delete(AppSettings.URLs.deleteUser + "?id=" + data.id)
                .then(() => {
                  getUsers();
                  toast.success("Deleted Successfully", {
                    toastId: customId,
                  });
                });
            } catch (error) {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            }
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  ///search Functionality

  const handleSearch = (value) => {
    if (value === null || value === "") {
      setFilteredData(data);
    } else {
      const temp = data.filter((object) => {
        for (const key in object) {
          if (object.hasOwnProperty(key) && typeof object[key] === "string") {
            if (object[key].toLowerCase().includes(value?.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      setFilteredData(temp);
      setCurrentPage(1);
    }
  };

  //Pagination
  const itemsPerPage = 10;
  const totalPages = filteredData
    ? Math.ceil(filteredData?.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const displayedData = filteredData
    ? filteredData.slice(startIndex, endIndex)
    : [];
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const goToNextPage = () => {
    if (endIndex < filteredData?.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const renderPageNumbers = () => {
    const maxPageNumbersToShow = 3; // Adjust this value as per your preference
    const pageNumbers = [];
    let startPage;
    let endPage;

    if (totalPages <= maxPageNumbersToShow) {
      // Show all page numbers if the total pages are less than or equal to the maxPageNumbersToShow
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate the start and end page numbers with an ellipsis
      const middlePage = Math.ceil(maxPageNumbersToShow / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = maxPageNumbersToShow;
      } else if (currentPage + middlePage - 1 >= totalPages) {
        startPage = totalPages - maxPageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage + 1;
        endPage = currentPage + middlePage - 1;
      }
    }

    // Add ellipsis at the beginning if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Add page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis at the end if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((pageNumber) =>
      typeof pageNumber === "number" ? (
        <li
          key={pageNumber}
          className={`inline-block cursor-pointer hover:backdrop-brightness-90 ${
            currentPage === pageNumber ? "current" : ""
          }`}
          onClick={() => setCurrentPage(pageNumber)}
        >
          <span>{pageNumber}</span>
        </li>
      ) : (
        <li key={pageNumber} className="inline-block mx-1">
          {pageNumber}
        </li>
      )
    );
  };

  return (
    <>
      <div className="page-titles bg-white px-6 ">
        <div className="flex items-center">
          <div className="mr-4 kr-page-back-btn">
            <NavLink to="/master" className="no-underline">
              <button
                type="button"
                className="btn-primary rounded flex items-center justify-center w-8"
              >
                <svg
                  width={18}
                  className="svg-white"
                  x="0px"
                  y="0px"
                  viewBox="0 0 700 700"
                >
                  <g>
                    <path
                      d="M82.9,331.2l148.3,148.3c7.6,7.6,18.6,10.6,29,7.8c10.4-2.8,18.5-10.9,21.2-21.2c2.8-10.4-0.2-21.4-7.8-29l-97.6-96.9
      h304.7c15.8,0,31,6.3,42.1,17.5c11.2,11.2,17.5,26.3,17.5,42.1v79c0,10.6,5.7,20.5,14.9,25.8c9.2,5.3,20.6,5.3,29.8,0
      c9.2-5.3,14.9-15.2,14.9-25.8v-79c0-31.6-12.6-61.9-34.9-84.3c-22.4-22.4-52.7-34.9-84.3-34.9H176.1l97.6-97.6
      c7.6-7.6,10.6-18.6,7.8-29c-2.8-10.4-10.9-18.5-21.2-21.2c-10.4-2.8-21.4,0.2-29,7.8L82.9,288.7c-5.7,5.6-8.9,13.3-8.9,21.2
      S77.2,325.6,82.9,331.2L82.9,331.2z"
                    ></path>
                  </g>
                </svg>
              </button>
            </NavLink>
          </div>
          <div>
            <h2 className="font-bold color-dark text-[14px] mb-0">
              User Management
            </h2>
            <span className="text-gray-400 text-[12px]">
              Manage your Users here
            </span>
          </div>
        </div>
      </div>
      <div className="pageBody-inner p-6">
        <div className="kr-block">
          <div className="card !border-none">
            <div className="card-inner p-0">
              <div className="kr-table-header border-bottom px-6 py-3  ">
                <div className="w-100 flex items-center mb-lg-0 -md-0 justify-end">
                  <div className="flex  h-[32px]">
                    <div className="flex place-content-center user-search-filter-parent sm:w-[200px]">
                      <input
                        onChange={(e) =>{ handleSearch(e.target.value);setSearchFilter(e.target.value)}}
                        type="text"
                        value={searchFilter}
                        placeholder="Search..."
                        className="user-search-filter-input"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary flex justify-center items-center min-w-80 "
                      onClick={() => handleSidebarOpen("add", null)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <div className="kr-block px-3 pt-2 pb-3">
                <div className="overflow-x-auto">
                  {loading ? (
                    <table className="table kr-table-striped">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Employee Id</th>
                          <th>Employee Name</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedData?.map((data, index) => (
                          <tr className="relative" key={index}>
                            <td className="whitespace-nowrap text-center">
                              {startIndex + index + 1}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.empid}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.username}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.designation}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.department}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.email}
                            </td>

                            {/* <td className='uppercase whitespace-nowrap'>{data.monitor1?.length>3&&data.monitor2?.length>3?"2":data.monitor1?.length>3||data.monitor2?.length>3?"1":"0"}</td> */}
                            <td className="kr-tbl-action">
                              <div className="flex justify-end shrink-0">
                                <ul>
                                  <li>
                                    {" "}
                                    <ViewAsset data={data} />
                                  </li>
                                  <li>
                                    <Tooltip
                                      title="Edit"
                                      placement="top"
                                      arrow
                                      leaveDelay={50}
                                    >
                                      {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "Edit" })}> */}
                                      <div
                                        className="kr-tbl-edit cursor-pointer action-item"
                                        onClick={() => {
                                          handleSidebarOpen("edit", data);
                                        }}
                                      >
                                        <span className="svg-icon">
                                          <svg
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 700 700"
                                          >
                                            <g>
                                              <path
                                                d="M631.3,133.8l-80-80C539.9,42.5,524.6,36,508.4,36c-16.2,0-31.5,6.5-42.8,17.8L117.1,402.2c-7.3,7.3-12.1,16.2-15.4,25.9
                                       l-49.3,169c-3.2,9.7,0,20.2,7.3,28.3c5.7,5.7,12.9,8.1,20.2,8.1c2.4,0,5.7,0,8.1-0.8l169-49.3c9.7-3.2,18.6-8.1,25.9-15.4
                                       l348.4-348.4c11.3-11.3,17.8-26.7,17.8-42.8C649.1,160.5,642.6,145.1,631.3,133.8L631.3,133.8z M147,475l63.1,63.1l-88.9,25.9
                                       L147,475z M590.9,179.9l-55,55l-85.7-85.7l55-55c0.8-0.8,2.4-0.8,3.2-0.8c0.8,0,1.6,0,3.2,0.8l80,80c0.8,0.8,0.8,2.4,0.8,3.2
                                       C592.5,178.3,591.7,179.1,590.9,179.9z"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </div>
                                    </Tooltip>
                                    {/* </OverlayTrigger> */}
                                  </li>
                                  <li>
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                      leaveDelay={50}
                                    >
                                      {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "Delete" })}> */}
                                      <div
                                        className="kr-tbl-delete cursor-pointer action-item"
                                        onClick={() => {
                                          deleteUser(data);
                                        }}
                                      >
                                        <span className="svg-icon">
                                          <svg
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 700 700"
                                          >
                                            <g>
                                              <path
                                                d="M598.3,144.5H475.1v-26.9c0-19-17.4-33-39.2-33H297.6c-21.8,0-39.2,14.6-39.2,33v26.9H135.7c-12.3,0-21.8,9.5-21.8,21.8
                                             c0,12.3,9.5,21.8,21.8,21.8h26.9l26.9,336.6c0.6,39.8,32.5,71.7,72.2,71.7h210c39.8,0,71.7-31.9,72.2-71.7l27.4-336h26.9
                                             c12.3,0,21.8-9.5,21.8-21.8C620.1,154.6,610.6,144.5,598.3,144.5L598.3,144.5z M302,127.7H432v16.8l-129.9,0V127.7z M310.4,479.4
                                             h-0.6c-11.8,0-20.7-9.5-21.8-20.7l-7.8-176.4c-0.6-12.3,8.4-22.4,20.7-22.4h0.6c11.8,0,20.7,9.5,21.8,20.7l7.8,176.4
                                             C331.7,468.7,322.8,478.8,310.4,479.4L310.4,479.4z M446,458.1c-0.6,11.8-10.1,20.7-21.8,20.7h-0.6c-12.3-0.6-20.7-10.6-20.7-22.4
                                             l7.8-176.4c0.6-11.8,10.1-20.7,21.8-20.7h0.6c12.3,0.6,20.7,10.6,20.7,22.4L446,458.1z"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </div>
                                    </Tooltip>
                                    {/* </OverlayTrigger> */}
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="w-100 flex justify-center items-center py-6 bg-light">
                      <div className="kr-pageLoader">
                        <span className="loader"></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="kr-block pt-3 pb-3 font-size-12px color-gray border-top px-3">
                <nav className="w-full position:relative m-0">
                  <ul className="list-none ml-0 mb-0 pagination">
                    <li
                      className={`inline-block pagination-prev cursor-pointer ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={goToPreviousPage}
                    >
                      <span>Previous</span>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`inline-block pagination-next cursor-pointer ${
                        endIndex >= data?.length ? "disabled" : ""
                      }`}
                      onClick={goToNextPage}
                    >
                      <span>Next</span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`kr-popup-overlay ${
          isSidebarOpen ? "kr-side-popup-overlay" : ""
        }`}
      ></div>
      <div
        className={`kr-side-popup ${
          isSidebarOpen ? "kr-side-popup-on !w-[90vw] md:!w-[400px]" : ""
        }`}
      >
        <div className="kr-block w-100">
          <div className="flex items-center py-[16px] px-6 bg-gray-50">
            <div className="pl-2">
              <h2 className="font-bold color-dark text-[14px] mb-0">
                {sidebarAction === "add" ? "Add User" : "Update User"}
              </h2>
              <span className="text-gray-400 text-[12px]">
                {sidebarAction === "add"
                  ? "Add new user"
                  : "Update user details"}
              </span>
            </div>
          </div>
          <div className="kr-block p-4">
            <div id="form">
              <div className="grid grid-cols-1 gap-1 ">
                <div className="">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Employee ID</label>
                    <input
                      type="text"
                      placeholder="Employee ID"
                      value={selectedValue?.empid?.label}
                      className={`form-control kr-form-textbox placeholder-[#212529]    ${
                        dataError.empid === false ? "" : "dataError"
                      }`}
                      onChange={(e) => handleInputChange(e, "empid")}
                      readOnly={sidebarAction === "edit"}
                    ></input>
                  </div>
                </div>
                <div className="">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Employee Name</label>
                    <input
                      type="text"
                      placeholder="Employee Name"
                      value={selectedValue?.username?.label}
                      className={`form-control kr-form-textbox placeholder-[#212529]  ${
                        dataError.username === false ? "" : "dataError"
                      }`}
                      onChange={(e) => handleInputChange(e, "username")}
                    ></input>
                  </div>
                </div>
                <div className="">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Designation</label>
                    <input
                      type="text"
                      placeholder="Designantion"
                      value={selectedValue?.designation?.label}
                      className={`form-control kr-form-textbox placeholder-[#212529]  ${
                        dataError.designation === false ? "" : "dataError"
                      }`}
                      onChange={(e) => handleInputChange(e, "designation")}
                    ></input>
                  </div>
                </div>
                <div className="">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Department</label>
                    <Select
                      placeholder="Team"
                      values={[selectedValue?.department]}
                      options={[
                        {
                          value: "Accounts & Finance",
                          label: "Accounts & Finance",
                        },
                        {
                          value: "Power Platform & Dynamics",
                          label: "Power Platform & Dynamics",
                        },
                        {
                          value: "Enterprise Accounts",
                          label: "Enterprise Accounts",
                        },
                        { value: "Data & AI", label: "Data & AI" },
                        {
                          value: "Corporate(Shared Services)",
                          label: "Corporate(Shared Services)",
                        },
                        {
                          value: "Cloud Native Apps (.Net)",
                          label: "Cloud Native Apps (.Net)",
                        },
                        {
                          value: "Azure (Cloud Practice)",
                          label: "Azure (Cloud Practice)",
                        },
                        {
                          value: "Admin & Other Office Personel",
                          label: "Admin & Other Office Personel",
                        },
                        {
                          value: "Technical Architect (Azure Solutions)",
                          label: "Technical Architect (Azure Solutions)",
                        },
                      ]}
                      onChange={(selectedOption) => {
                        handleInputChange(selectedOption, "department");
                      }}
                      name="department"
                      className={`w-100 ${
                        dataError?.department === false ? "" : "dataError"
                      }`}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      value={selectedValue?.email?.label}
                      className={`form-control kr-form-textbox placeholder-[#212529]  ${
                        dataError.email === false ? "" : "dataError"
                      }`}
                      onChange={(e) => handleInputChange(e, "email")}
                    ></input>
                  </div>
                </div>
              </div>

              <div className=" mt-3">
                <div className="">
                  <div className="form-group flex justify-end">
                    {/* <Link to ='/' className='no-underline'> */}
                    <button
                      type="button"
                      className="flex items-center justify-center py-[6px] px-[12px] btn-light border border-solid border-gray-200 rounded min-w-80"
                      onClick={handleSidebarClose}
                    >
                      Cancel
                    </button>
                    {/* </Link> */}
                    {/* <Link to ='/' className='no-underline'> */}
                    <button
                      type="button"
                      className="btn btn-primary flex items-center justify-center ml-2 min-w-80"
                      onClick={handleSidebarFormSubmit}
                    >
                      {sidebarAction === "add" ? "Add" : "Update"}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer transition={Slide} /> */}
    </>
  );
}

export default UserManagement;
