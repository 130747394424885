import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import "../assets/css/main.css";
import "./system-management.css";
import { AppSettings } from "../../constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../axios-interceptor";

function SystemManagement() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const customId = "custom-id-yes";
  const [sidebarAction, setSidebarAction] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [addProp, setAddProp] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [dataError, setDataError] = useState({
    computerId: false,
    type: false,
    cpu: false,
    monitor1: false,
    monitor2: false,
    webcam: false,
    laptop: false,
    ipAdress: false,
    windowsOS: false,
    softPhone: false,
    isFirewall: false,
    isVPN: false,
    isWindowsAgent: false,
  });
  const [selectedValue, setSelectedValue] = useState({
    computerId: { value: "", label: "computerId" },
    type: { value: null, label: "type" },
    laptop: { value: null, label: "Laptop" },
    cpu: { value: null, label: "CPU" },
    monitor1: { value: null, label: "M-1" },
    monitor2: { value: null, label: "M-2" },
    webcam: { value: null, label: "Webcam" },
    ipAdress: { value: null, label: "" },
    windowsOS: { value: null, label: "windows OS" },
    softPhone: { value: null, label: "" },
    isFirewall: { value: null, label: "Firewall" },
    isVPN: { value: null, label: "VPN" },
    isWindowsAgent: { value: null, label: "Windows Agent" },
  });

  //To get all Systems
  const getSystems = async () => {
    try {
      const res = await axiosInstance.get(AppSettings.URLs.getSystems);
      if (res != null) {
        setData(res?.data?.response?.system);
        setLoading(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getSystems();
    fetchDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get dropdown data
  const fetchDropdownOptions = async () => {
    try {
      const CPUData = await axiosInstance.get(
        AppSettings.URLs.getAsset + "CPU/Unassigned"
      );
      const MonitorData = await axiosInstance.get(
        AppSettings.URLs.getAsset + "Monitor/Unassigned"
      );
      const WebCamData = await axiosInstance.get(
        AppSettings.URLs.getAsset + "Webcam/Unassigned"
      );
      const LaptopData = await axiosInstance.get(
        AppSettings.URLs.getAsset + "Laptop/Unassigned"
      );
      const WindowsOSData = await axiosInstance.get(
        AppSettings.URLs.getAsset + "System/WindowsOS"
      );

      setDropdownOptions({
        cpu: CPUData?.data.response.cpUs,
        monitor: MonitorData?.data.response.monitor,
        webcam: WebCamData?.data.response.webcam,
        laptop: LaptopData?.data.response?.laptop,
        WindowsOS: WindowsOSData?.data.response.information.windowsOSList,
      });
    } catch (error) {
      console.log("Error fetching dropdown options:", error);
    }
  };

  // To handle the input form the user
  const handleInputChange = (e, name) => {
    let value;
    if (e && e.target) {
      // Regular input field
      value = e?.target?.value;
      setSelectedValue((option) => ({
        ...option,
        [name]: { value: null, label: value },
      }));
    } else {
      // React Select component
      value = e ? e[0]?.value : "";
      setSelectedValue((option) => ({
        ...option,
        [name]: { value: e ? e[0]?.value : "", label: e ? e[0]?.label : "" },
      }));
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
      computerId: prevInputValues?.computerId ? prevInputValues.computerId : "",
    }));
  };

  //To Open sideBar
  const handleSidebarOpen = (action, item) => {
    setIsSidebarOpen(true);
    setSidebarAction(action);
    setInputValues(item);
    setEditItemId(item?.id);
    fetchDropdownOptions();
    if (item !== null) {
      setSelectedValue({
        computerId: { value: item?.computerId, label: item?.computerId },
        type: { value: item?.type, label: item?.type },
        laptop: { value: item?.laptop, label: item?.laptop },
        cpu: { value: item?.cpu, label: item?.cpu },
        monitor1: {
          value: item?.monitor1,
          label: item?.monitor1 === "" ? "M-1" : item?.monitor1,
        },
        monitor2: {
          value: item?.monitor2,
          label: item?.monitor2 === "" ? "M-1" : item?.monitor1,
        },
        webcam: {
          value: item?.webcam,
          label: item?.webcam === "" ? "Webcam" : item?.webcam,
        },
        ipAdress: { value: item?.ipAdress, label: item.ipAdress },
        windowsOS: { value: item?.windowsOS, label: item?.windowsOS },
        softPhone: { value: item?.softPhone, label: item?.softPhone },
        isFirewall: {
          value: item?.isFirewall,
          label: item?.isFirewall === true ? "Yes" : "No",
        },
        isVPN: {
          value: item?.isVPN,
          label: item?.isVPN === true ? "Yes" : "No",
        },
        isWindowsAgent: {
          value: item?.isWindowsAgent,
          label: item?.isWindowsAgent === true ? "Yes" : "No",
        },
        status: {
          value: item.isAssigned,
          label: item?.isAssigned ? "Assigned" : "Unassigned",
        },
      });
    } else {
      setSelectedValue({
        computerId: { value: "", label: "computerId" },
        type: { value: null, label: "type" },
        laptop: { value: null, label: "Laptop" },
        cpu: { value: null, label: "CPU" },
        monitor1: { value: null, label: "M-1" },
        monitor2: { value: null, label: "M-2" },
        webcam: { value: null, label: "Webcam" },
        ipAdress: { value: null, label: "" },
        windowsOS: { value: null, label: "windows OS" },
        softPhone: { value: null, label: "" },
        isFirewall: { value: null, label: "Firewall" },
        isVPN: { value: null, label: "VPN" },
        isWindowsAgent: { value: null, label: "Windows Agent" },
      });
    }
  };

  //To Close SideBar
  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
    setSidebarAction("");
    setEditItemId(null);
    setInputValues({});
    setSelectedValue({
      computerId: { value: null, label: "computerId" },
      type: { value: null, label: "type" },
      laptop: { value: null, label: "Laptop" },
      cpu: { value: null, label: "CPU" },
      monitor1: { value: null, label: "M-1" },
      monitor2: { value: null, label: "M-2" },
      webcam: { value: null, label: "Webcam" },
      ipAdress: { value: null, label: "" },
      windowsOS: { value: null, label: "windows OS" },
      softPhone: { value: null, label: "" },
      isFirewall: { value: null, label: "Firewall" },
      isVPN: { value: null, label: "VPN" },
      isWindowsAgent: { value: null, label: "Windows Agent" },
    });
    setDataError({
      computerId: false,
      type: false,
      cpu: false,
      monitor1: false,
      monitor2: false,
      webcam: false,
      laptop: false,
      ipAdress: false,
      windowsOS: false,
      softPhone: false,
      isFirewall: false,
      isVPN: false,
      isWindowsAgent: false,
    });
  };

  //To handle submit action of Sidebar
  const handleSidebarFormSubmit = (event) => {
    let isFormFilled = false;
    setDataError({
      computerId: inputValues?.computerId === undefined ? true : false,
      type: inputValues?.type === undefined ? true : false,
      cpu: inputValues?.cpu === undefined ? true : false,
      monitor1:
        inputValues?.monitor1 === undefined || inputValues?.monitor1 === ""
          ? true
          : false,
      monitor2:
        inputValues?.monitor2 === undefined || inputValues?.monitor2 === ""
          ? true
          : false,
      webcam:
        inputValues?.webcam === undefined || inputValues?.webcam === ""
          ? true
          : false,
      laptop: inputValues?.laptop === undefined ? true : false,
      ipAdress:
        inputValues?.ipAdress === undefined || inputValues?.ipAdress === ""
          ? true
          : false,
      windowsOS: inputValues?.windowsOS === undefined ? true : false,
      softPhone:
        inputValues?.softPhone === undefined || inputValues?.softPhone === ""
          ? true
          : false,
      isFirewall: inputValues?.isFirewall === undefined ? true : false,
      isVPN: inputValues?.isVPN === undefined ? true : false,
      isWindowsAgent: inputValues?.isWindowsAgent === undefined ? true : false,
    });

    if (inputValues?.type === "CPU") {
      if (
        inputValues?.monitor1 !== undefined &&
        inputValues.monitor2 !== undefined
      ) {
        if (inputValues?.monitor1 === inputValues?.monitor2) {
          setDataError((prevDataError) => ({
            ...prevDataError,
            monitor1: true,
            monitor2: true,
          }));
          isFormFilled = false;
          toast.error("Monitors cannot be same", {
            toastId: customId,
          });
        }
        // else if(inputValues?.monitor1!==inputValues?.monitor2){
        //   setDataError((prevDataError) => ({
        //     ...prevDataError,
        //     monitor1:  false ,
        //     monitor2: false,
        //   }));
        // }
      }
    }

    if (
      inputValues?.type !== undefined &&
      inputValues.ipAdress !== undefined &&
      inputValues.ipAdress !== "" &&
      inputValues.windowsOS !== undefined &&
      inputValues.softPhone !== undefined &&
      inputValues.softPhone !== "" &&
      inputValues.isFirewall !== undefined &&
      inputValues.isVPN !== undefined &&
      inputValues.isWindowsAgent !== undefined
    ) {
      if (inputValues.type === "CPU") {
        if (
          inputValues.cpu !== undefined &&
          inputValues.monitor1 !== undefined &&
          inputValues.monitor1 !== "" &&
          inputValues.monitor2 !== undefined &&
          inputValues.monitor2 !== "" &&
          inputValues?.monitor1 !== inputValues?.monitor2 &&
          inputValues.webcam !== undefined &&
          inputValues.webcam !== ""
        ) {
          isFormFilled = true;
        }
      } else if (inputValues.type === "Laptop") {
        if (inputValues.laptop !== undefined) {
          isFormFilled = true;
        }
      }
    }

    if (isFormFilled === true) {
      if (sidebarAction === "add") {
        try {
          axiosInstance
            .post(AppSettings.URLs.getSystems, inputValues)
            .then(() => {
              getSystems();
              toast.success("Submitted Successfully", {
                toastId: customId,
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
      } else if (sidebarAction === "edit") {
        try {
          axiosInstance
            .put(AppSettings.URLs.getSystems + editItemId, inputValues)
            .then(() => {
              getSystems();
              toast.success("Updated Successfully", {
                toastId: customId,
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
      }
      handleSidebarClose();
      setInputValues({});
    } else {
      toast.error("Please fill all the required fields", {
        toastId: customId,
      });
    }
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-0 pt-6 px-6 pb-2">
          <h2 className="font-bold color-dark mb-0 text-[14px] uppercase">
            Please add {props.addprop[0]}
          </h2>
        </Modal.Header>
        <Modal.Body className="py-0 px-6">
          <div className="row mt-2">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group mb-2">
                <label className="kr-form-label">Add {props.addprop[0]}</label>
                <input
                  type="text"
                  placeholder={props.addprop[0]}
                  className="form-control kr-form-textbox"
                  onChange={handlePopupInput}
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-start bg-white py-3 px-6 mt-3">
          <Button className="btn btn-secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button
            className="btn btn-Primary"
            onClick={() => {
              props.onHide();
              addDropDown(props.addprop[0]);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const showModal = async (event) => {
    await setAddProp(event);
    setModalShow(true);
  };

  let temp;
  const handlePopupInput = async (event) => {
    // event.preventDefault()
    temp = event?.target?.value;
    // await setPopUpInput(event.target.value);
  };

  const addDropDown = (data) => {
    try {
      axiosInstance
        .post(
          AppSettings.URLs.getAsset + "System/WindowsOS?addWindowsOS=" + temp
        )
        .then(() => {
          fetchDropdownOptions();
          toast.success("Submitted Successfully", {
            toastId: customId,
          });
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            toastId: customId,
          });
        });
    } catch (error) {
      toast.error("Something went wrong", {
        toastId: customId,
      });
    }
  };

  //Pagination
  const itemsPerPage = 10;
  const totalPages = data ? Math.ceil(data?.length / itemsPerPage) : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const displayedData = data ? data.slice(startIndex, endIndex) : [];
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const goToNextPage = () => {
    if (endIndex < data?.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const renderPageNumbers = () => {
    const maxPageNumbersToShow = 3; // Adjust this value as per your preference
    const pageNumbers = [];
    let startPage;
    let endPage;

    if (totalPages <= maxPageNumbersToShow) {
      // Show all page numbers if the total pages are less than or equal to the maxPageNumbersToShow
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate the start and end page numbers with an ellipsis
      const middlePage = Math.ceil(maxPageNumbersToShow / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = maxPageNumbersToShow;
      } else if (currentPage + middlePage - 1 >= totalPages) {
        startPage = totalPages - maxPageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage + 1;
        endPage = currentPage + middlePage - 1;
      }
    }

    // Add ellipsis at the beginning if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Add page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis at the end if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((pageNumber) =>
      typeof pageNumber === "number" ? (
        <li
          key={pageNumber}
          className={`inline-block cursor-pointer hover:backdrop-brightness-90 ${
            currentPage === pageNumber ? "current" : ""
          }`}
          onClick={() => setCurrentPage(pageNumber)}
        >
          <span>{pageNumber}</span>
        </li>
      ) : (
        <li key={pageNumber} className="inline-block mx-1">
          {pageNumber}
        </li>
      )
    );
  };

  return (
    <>
      <div className="page-titles bg-white px-6 ">
        <div className="flex items-center">
          <div className="mr-4 kr-page-back-btn">
            <NavLink to="/master" className="no-underline">
              <button
                type="button"
                className="btn-primary rounded flex items-center justify-center w-8"
              >
                <svg
                  width={18}
                  className="svg-white"
                  x="0px"
                  y="0px"
                  viewBox="0 0 700 700"
                >
                  <g>
                    <path
                      d="M82.9,331.2l148.3,148.3c7.6,7.6,18.6,10.6,29,7.8c10.4-2.8,18.5-10.9,21.2-21.2c2.8-10.4-0.2-21.4-7.8-29l-97.6-96.9
      h304.7c15.8,0,31,6.3,42.1,17.5c11.2,11.2,17.5,26.3,17.5,42.1v79c0,10.6,5.7,20.5,14.9,25.8c9.2,5.3,20.6,5.3,29.8,0
      c9.2-5.3,14.9-15.2,14.9-25.8v-79c0-31.6-12.6-61.9-34.9-84.3c-22.4-22.4-52.7-34.9-84.3-34.9H176.1l97.6-97.6
      c7.6-7.6,10.6-18.6,7.8-29c-2.8-10.4-10.9-18.5-21.2-21.2c-10.4-2.8-21.4,0.2-29,7.8L82.9,288.7c-5.7,5.6-8.9,13.3-8.9,21.2
      S77.2,325.6,82.9,331.2L82.9,331.2z"
                    ></path>
                  </g>
                </svg>
              </button>
            </NavLink>
          </div>
          <div>
            <h2 className="font-bold color-dark text-[14px] mb-0">
              System Management
            </h2>
            <span className="text-gray-400 text-[12px]">
              Manage your systems here
            </span>
          </div>
        </div>
      </div>
      <div className="pageBody-inner p-6">
        <div className="kr-block">
          <div className="card !border-none">
            <div className="card-inner p-0">
              <div className="kr-table-header border-bottom px-6 py-3">
                <div className="w-100 flex items-center mb-lg-0 -md-0">
                  <div className="kr-table-filter">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="flex items-center p-2 px-3 btn btn-light border border-solid border-gray-200 btn"
                        aria-expanded="false"
                      >
                        <svg width="12" x="0px" y="0px" viewBox="0 0 700 700">
                          <g>
                            <path
                              d="M618.3,89.7H81.7c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.8,22.4,38.7,22.4h536.6
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C649,98.2,634.3,89.7,618.3,89.7z"
                            ></path>
                            <path
                              d="M528.9,313.3H171.1c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.8,22.4,38.7,22.4h357.7
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C559.6,321.8,544.8,313.3,528.9,313.3L528.9,313.3z"
                            ></path>
                            <path
                              d="M439.4,536.9H260.6c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.7,22.4,38.7,22.4h178.9
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C470.2,545.4,455.4,536.9,439.4,536.9z"
                            ></path>
                          </g>
                        </svg>
                        <span className="pl-2">Filter</span>
                      </button>
                    </div>
                  </div>
                  <div className="ms-auto lg-flex md-flex block kr-table-header-action">
                    {/* <Link to="/add" className="no-underline"> */}
                    <button
                      type="submit"
                      className="btn btn-primary flex justify-center items-center min-w-80 "
                      onClick={() => handleSidebarOpen("add", null)}
                    >
                      Add
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>

              <div className="kr-block px-3 pt-2 pb-3">
                <div className="overflow-x-auto">
                  {loading ? (
                    <table className="table kr-table-striped">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Computer Id</th>
                          <th>Type</th>
                          <th>CPU</th>
                          <th>M-1</th>
                          <th>M-2</th>
                          <th>W. Cam.</th>
                          <th>Laptop</th>
                          <th>IP Address</th>
                          <th>Windows OS</th>
                          <th>Softphone(No.)</th>
                          <th>Firewall</th>
                          <th>VPN</th>
                          <th>Windows Agent</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedData?.map((data, index) => (
                          <tr className="relative" key={index}>
                            <td className="whitespace-nowrap text-center">
                              {startIndex + index + 1}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.computerId}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.type}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.cpu ? data.cpu : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.monitor1 ? data.monitor1 : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.monitor2 ? data.monitor2 : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.webcam ? data.webcam : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.laptop ? data.laptop : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.ipAdress ? data.ipAdress : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.windowsOS ? data.windowsOS : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.softPhone ? data.softPhone : "---"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.isFirewall ? "Yes" : "No"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.isVPN ? "Yes" : "No"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.isWindowsAgent ? "Yes" : "No"}
                            </td>
                            <td className="uppercase whitespace-nowrap">
                              {data.isAssigned ? "Assigned" : "unassigned"}
                            </td>
                            <td className="kr-tbl-action">
                              <div className="flex justify-end shrink-0">
                                <ul>
                                  <li>
                                    <div
                                      className="kr-tbl-edit cursor-pointer action-item"
                                      onClick={() => {
                                        handleSidebarOpen("edit", data);
                                      }}
                                    >
                                      <span className="svg-icon">
                                        <svg
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 700 700"
                                        >
                                          <g>
                                            <path
                                              d="M631.3,133.8l-80-80C539.9,42.5,524.6,36,508.4,36c-16.2,0-31.5,6.5-42.8,17.8L117.1,402.2c-7.3,7.3-12.1,16.2-15.4,25.9
                                       l-49.3,169c-3.2,9.7,0,20.2,7.3,28.3c5.7,5.7,12.9,8.1,20.2,8.1c2.4,0,5.7,0,8.1-0.8l169-49.3c9.7-3.2,18.6-8.1,25.9-15.4
                                       l348.4-348.4c11.3-11.3,17.8-26.7,17.8-42.8C649.1,160.5,642.6,145.1,631.3,133.8L631.3,133.8z M147,475l63.1,63.1l-88.9,25.9
                                       L147,475z M590.9,179.9l-55,55l-85.7-85.7l55-55c0.8-0.8,2.4-0.8,3.2-0.8c0.8,0,1.6,0,3.2,0.8l80,80c0.8,0.8,0.8,2.4,0.8,3.2
                                       C592.5,178.3,591.7,179.1,590.9,179.9z"
                                            ></path>
                                          </g>
                                        </svg>
                                      </span>
                                    </div>
                                  </li>
                                  {/* <li>
                                    <div
                                      className="kr-tbl-delete cursor-pointer action-item"
                                     
                                      href="#"
                                    >
                                      <span className="svg-icon">
                                      <svg x="0px" y="0px" viewBox="0 0 700 700">
                                          <g>
                                            <path d="M598.3,144.5H475.1v-26.9c0-19-17.4-33-39.2-33H297.6c-21.8,0-39.2,14.6-39.2,33v26.9H135.7c-12.3,0-21.8,9.5-21.8,21.8
                                             c0,12.3,9.5,21.8,21.8,21.8h26.9l26.9,336.6c0.6,39.8,32.5,71.7,72.2,71.7h210c39.8,0,71.7-31.9,72.2-71.7l27.4-336h26.9
                                             c12.3,0,21.8-9.5,21.8-21.8C620.1,154.6,610.6,144.5,598.3,144.5L598.3,144.5z M302,127.7H432v16.8l-129.9,0V127.7z M310.4,479.4
                                             h-0.6c-11.8,0-20.7-9.5-21.8-20.7l-7.8-176.4c-0.6-12.3,8.4-22.4,20.7-22.4h0.6c11.8,0,20.7,9.5,21.8,20.7l7.8,176.4
                                             C331.7,468.7,322.8,478.8,310.4,479.4L310.4,479.4z M446,458.1c-0.6,11.8-10.1,20.7-21.8,20.7h-0.6c-12.3-0.6-20.7-10.6-20.7-22.4
                                             l7.8-176.4c0.6-11.8,10.1-20.7,21.8-20.7h0.6c12.3,0.6,20.7,10.6,20.7,22.4L446,458.1z"></path>
                                          </g>
                                        </svg>
                                      </span>
                                    </div>
                                  </li> */}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="w-100 flex justify-center items-center py-6 bg-light">
                      <div className="kr-pageLoader">
                        <span className="loader"></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="kr-block pt-3 pb-3 font-size-12px color-gray border-top px-3">
                <nav className="w-full position:relative m-0">
                  <ul className="list-none ml-0 mb-0 pagination">
                    <li
                      className={`inline-block pagination-prev cursor-pointer ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={goToPreviousPage}
                    >
                      <span>Previous</span>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`inline-block pagination-next cursor-pointer ${
                        endIndex >= data?.length ? "disabled" : ""
                      }`}
                      onClick={goToNextPage}
                    >
                      <span>Next</span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`kr-popup-overlay ${
          isSidebarOpen ? "kr-side-popup-overlay" : ""
        }`}
      ></div>
      <div
        className={`kr-side-popup md:!w-[600px] !w-[90vw] ${
          isSidebarOpen ? "kr-side-popup-on" : ""
        }`}
      >
        <div className="kr-block w-100">
          <div className="flex items-center py-[16px] px-6 bg-gray-50">
            <div className="pl-2">
              <h2 className="font-bold color-dark text-[14px] mb-0">
                {sidebarAction === "add" ? "Create System" : "Update System"}
              </h2>
              <span className="text-gray-400 text-[12px]">
                You can add new building here
              </span>
            </div>
          </div>
          <div className="kr-block p-4">
            <div id="form">
              <div className="grid md:!grid-cols-2 gap-x-4 !grid-cols-1 ">
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform">
                    <label className="kr-form-label">Computer ID</label>
                    <input
                      type="text"
                      value={selectedValue?.computerId?.value}
                      placeholder="Computer ID"
                      className="form-control kr-form-textbox"
                      readOnly
                    ></input>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Type</label>
                    <Select
                      placeholder="Type"
                      values={[selectedValue?.type]}
                      options={[
                        { value: "CPU", label: "CPU" },
                        { value: "Laptop", label: "Laptop" },
                      ]}
                      onChange={(selectedOption) => {
                        if (sidebarAction === "add") {
                          setInputValues({});
                          setSelectedValue({
                            computerId: { value: null, label: "computerId" },
                            type: { value: null, label: "type" },
                            laptop: { value: null, label: "Laptop" },
                            cpu: { value: null, label: "CPU" },
                            monitor1: { value: null, label: "M-1" },
                            monitor2: { value: null, label: "M-2" },
                            webcam: { value: null, label: "Webcam" },
                            ipAdress: { value: null, label: "" },
                            windowsOS: { value: null, label: "windows OS" },
                            softPhone: { value: null, label: "" },
                            isFirewall: { value: null, label: "Firewall" },
                            isVPN: { value: null, label: "VPN" },
                            isWindowsAgent: {
                              value: null,
                              label: "Windows Agent",
                            },
                          });

                          setDataError({
                            computerId: false,
                            type: false,
                            cpu: false,
                            monitor1: false,
                            monitor2: false,
                            webcam: false,
                            laptop: false,
                            ipAdress: false,
                            windowsOS: false,
                            softPhone: false,
                            isFirewall: false,
                            isVPN: false,
                            isWindowsAgent: false,
                          });
                        }
                        handleInputChange(selectedOption, "type");
                      }}
                      name="Type"
                      className={`w-100 ${
                        dataError?.type === false ? "" : "dataError"
                      }`}
                      disabled={sidebarAction === "edit"}
                    />
                  </div>
                </div>

                {inputValues?.type === "CPU" ? (
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">CPU</label>
                      <Select
                        placeholder="CPU"
                        name="CPU"
                        values={[selectedValue?.cpu]}
                        options={dropdownOptions?.cpu?.map((option) => ({
                          value: option.productNo,
                          label: option.productNo,
                        }))}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "cpu")
                        }
                        className={`w-100 ${
                          dataError.cpu === false ? "" : "dataError"
                        }`}
                        disabled={sidebarAction === "edit"}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {inputValues?.type === "CPU" ? (
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">M-1</label>
                      <Select
                        placeholder="M-1"
                        name="M-1"
                        values={[selectedValue?.monitor1]}
                        options={dropdownOptions?.monitor?.map((option) => ({
                          value: option.productNo,
                          label: option.productNo,
                        }))}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "monitor1")
                        }
                        className={`w-100 ${
                          dataError.monitor1 === false ? "" : "dataError"
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {inputValues?.type === "CPU" ? (
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">M-2</label>
                      <Select
                        placeholder="M-2"
                        name="M-2"
                        values={[selectedValue?.monitor2]}
                        options={dropdownOptions?.monitor?.map((option) => ({
                          value: option.productNo,
                          label: option.productNo,
                        }))}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "monitor2")
                        }
                        className={`w-100 ${
                          dataError.monitor2 === false ? "" : "dataError"
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {inputValues?.type === "CPU" ? (
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Web cam</label>
                      <Select
                        placeholder="Web cam"
                        name="Webcam"
                        values={[selectedValue?.webcam]}
                        options={dropdownOptions?.webcam?.map((option) => ({
                          value: option.productNo,
                          label: option.productNo,
                        }))}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "webcam")
                        }
                        className={`w-100 ${
                          dataError.webcam === false ? "" : "dataError"
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {inputValues?.type === "Laptop" ? (
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Laptop</label>
                      <Select
                        placeholder="Laptop"
                        name="Laptop"
                        values={[selectedValue?.laptop]}
                        options={dropdownOptions?.laptop?.map((option) => ({
                          value: option.productNo,
                          label: option.productNo,
                        }))}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "laptop")
                        }
                        className={`w-100 ${
                          dataError.laptop === false ? "" : "dataError"
                        }`}
                        disabled={sidebarAction === "edit"}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">IP Address</label>
                    <input
                      type="text"
                      placeholder="IP Address"
                      value={selectedValue?.ipAdress?.label}
                      className={`form-control kr-form-textbox ${
                        dataError.ipAdress === false ? "" : "dataError"
                      }`}
                      onChange={(e) => handleInputChange(e, "ipAdress")}
                    ></input>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Windows OS</label>
                    <div className=" kr-selectform flex">
                      <Select
                        placeholder="Windows OS"
                        name="WindowsOS"
                        values={[selectedValue?.windowsOS]}
                        options={dropdownOptions?.WindowsOS?.map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "windowsOS")
                        }
                        className={`w-100 ${
                          dataError.windowsOS === false ? "" : "dataError"
                        }`}
                        disabled={sidebarAction === "edit"}
                      />
                      <button
                        type="button"
                        className=" btn-primary rounded !rounded-tl-none !rounded-bl-none flex items-center justify-center w-[46px]"
                        onClick={() => showModal(["WindowsOS"])}
                      >
                        <svg
                          width={10}
                          className="svg-white"
                          x="0px"
                          y="0px"
                          viewBox="0 0 34.81 34.81"
                        >
                          <g>
                            <path d="M17.41,0C16.14,0,15.12,1.03,15.12,2.29V15.12H2.29c-1.26,0-2.29,1.03-2.29,2.29s1.03,2.29,2.29,2.29H15.12v12.83c0,1.26,1.03,2.29,2.29,2.29s2.29-1.03,2.29-2.29v-12.83h12.83c1.26,0,2.29-1.03,2.29-2.29s-1.03-2.29-2.29-2.29h-12.83V2.29c0-1.26-1.03-2.29-2.29-2.29h0Z"></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Softphone no.</label>
                    <input
                      type="text"
                      placeholder="Softphone no."
                      value={selectedValue?.softPhone?.label}
                      className={`form-control kr-form-textbox ${
                        dataError.softPhone === false ? "" : "dataError"
                      }`}
                      onChange={(e) => handleInputChange(e, "softPhone")}
                    ></input>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Firewall</label>
                    <Select
                      placeholder="Firewall"
                      values={[selectedValue?.isFirewall]}
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      onChange={(selectedOption) =>
                        handleInputChange(selectedOption, "isFirewall")
                      }
                      name="Firewall"
                      className={`w-100 ${
                        dataError.isFirewall === false ? "" : "dataError"
                      }`}
                    />
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">VPN</label>
                    <Select
                      placeholder="VPN"
                      values={[selectedValue?.isVPN]}
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      onChange={(selectedOption) =>
                        handleInputChange(selectedOption, "isVPN")
                      }
                      name="VPN"
                      className={`w-100 ${
                        dataError.isVPN === false ? "" : "dataError"
                      }`}
                    />
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Windows agent</label>
                    <Select
                      placeholder="Windows agent"
                      values={[selectedValue?.isWindowsAgent]}
                      name="WindowsAgent"
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      onChange={(selectedOption) =>
                        handleInputChange(selectedOption, "isWindowsAgent")
                      }
                      className={`w-100 ${
                        dataError.isWindowsAgent === false ? "" : "dataError"
                      }`}
                      disabled={sidebarAction === "edit"}
                    />
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Status</label>
                    <input
                      type="text"
                      placeholder="Status"
                      value={selectedValue?.status?.label}
                      className="form-control kr-form-textbox"
                      onChange={(e) => handleInputChange(e, "status")}
                      readOnly
                    ></input>
                  </div>
                </div>
              </div>

              <div className="col-span-2 mt-3">
                <div className="form-group flex justify-end">
                  {/* <Link to ='/' className='no-underline'> */}
                  <button
                    type="button"
                    className="flex items-center justify-center py-[6px] px-[12px] btn-light border border-solid border-gray-200 rounded min-w-80"
                    onClick={handleSidebarClose}
                  >
                    Cancel
                  </button>
                  {/* </Link> */}
                  {/* <Link to ='/' className='no-underline'> */}
                  <button
                    type="button"
                    className="btn btn-primary flex items-center justify-center ml-2 min-w-80"
                    onClick={handleSidebarFormSubmit}
                  >
                    {sidebarAction === "add" ? "Add" : "Update"}
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MyVerticallyCenteredModal
        addprop={addProp}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* <ToastContainer transition={Slide} /> */}
    </>
  );
}

export default SystemManagement;
