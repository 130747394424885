const APIEndPoint = "https://inventoryapi.sysfore.com/api/";
export const AppSettings = {
  URLs: {
    getAllUsers: APIEndPoint + "UserData/GetAllUsersAsync",
    getUnassignUsers: APIEndPoint + "/AssetAllocate/GetAllUnAssignedUsersAsync",
    getUserById: APIEndPoint + "UserData/GetUserByIdAsync?id=",
    addUser: APIEndPoint + "UserData/AddUserDataAsync",
    updateUser: APIEndPoint + "UserData/UpdateUserDetailAsync",
    deleteUser: APIEndPoint + "UserData/DeleteUserDataAsync",
    asset: APIEndPoint + "",
    assetAllocate: APIEndPoint + "AssetAllocate/AssetAllocateAsync",
    updateAssetAllocate:
      APIEndPoint + "AssetAllocate/UpdateAssetAllocatedAsync",
    getAssetHistoryById: APIEndPoint + "AssetAllocate/GetAssetHistoryIdAsync",
    login: APIEndPoint + "Auth/VerifyUserLogin",
  },
};
