import React from "react";
import "./app-footer.css";
import logo from "../assets/images/Sysfore-logo-dark.svg";
import { useParams, useLocation } from "react-router-dom";

function AppFooter() {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div id="page_footer" className={`${currentPath === "/" ? "hidden" : ""}`}>
      <div className="w-full">
        <div className="row">
          <div className="lg:flex sm:block items-center w-full">
            <div>
              <p className="p-0 m-0 text-[11px]">
                © 2023 Sysfore Technologies - All Rights Reserved
              </p>
            </div>
            <div className="ms-auto">
              <p className="p-0 m-0 flex gap-2 text-[11px]">
                Powered by
                <a
                  href="https://www.sysfore.com"
                  alt=""
                  target="blank"
                  className="kr-powered-by ps-1"
                >
                  <img
                    alt="Sysfore"
                    width={70}
                    title="sysfore"
                    src={logo}
                  ></img>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppFooter;
